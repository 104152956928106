export default {
    responseType: 'code',
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    clientSecret: process.env.REACT_APP_SSO_CLIENT_SECRET,
    grantType: 'authorization_code',
    redirectUri: process.env.REACT_APP_SSO_REDIRECT_URL,
    scope: 'openid+profile',
    SSO_URI: process.env.REACT_APP_SSO_URL,
    SSO_AUTH_URI: process.env.REACT_APP_SSO_AUTH_URL || "tet",
    isEnabled: true
};
