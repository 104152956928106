import styled from "styled-components";
import { fontColor } from "../../styles/font-color";

const { whiteSmoke, blueStreak } = fontColor;
export const StyledErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: ${whiteSmoke};
`;

export const StyledErrorTitle = styled.span`
  font-size: 42px;
  line-height: 49px;
  color: ${blueStreak};
  margin-top: 30px;
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 28px;
  color: #54657a;
  margin-top: 30px;
  height: 66px;
`;

export const StyledSpan = styled.div`
  font-weight: 600;
`;
