import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import authClient from "./api/api";
import {
  generateRandomValue,
  getStoredAccessToken,
  setCodeToken,
} from "./utils/utils";

import { UserRole } from "@models/interfaces/user-roles";
import { CenteredLoadingSpinner } from "../../components/loading-component";
import { coreData } from "../../store";
import { getUserDetailsFromToken } from "../../utils/helper";
import config from "./config/config";
import { getStateKey, setStateKey, setStoredAccessToken } from "./utils/utils";

const codeMatch = window.location.href.match("[?#&]code=([^&]*)");
const stateMatch = window.location.href.match("[?#&]state=([^&]*)");
/**
 *
 * @returns Authenticate a user using the selected IDP and sets the details in sessionStorage
 */

export const CoreIdpAuth = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  //load store props
  const {
    setIsloggedIn,
    setUserAccess,
    setUserId,
    setUserEmail,
    setLoginType,
    setUserRole,
    setFirstName,
    setLastName,
  } = coreData();
  if (stateMatch && !stateMatch[1] && stateMatch[1] != getStateKey()) {
    navigate("/error");
  }
  //test case

  // const authStub = {
  //   access_token:
  //     "", //access token goes here
  //   loginType: "uhg-ms-id",
  //   userRole: "Admin" as UserRole,
  // };

  //connect with api
  const authStub: any = undefined;

  useEffect(() => {
    if (!loggedIn) {
      if (config.isEnabled) {
        // Replace current URL without adding it to history entries

        if (authStub !== undefined) {
          const token = authStub["access_token"];

          const decodedToken: any = getUserDetailsFromToken(token);

          setStoredAccessToken(token);
          sessionStorage.setItem("isLoggedIn", "true");

          const [fname, lname] = (decodedToken as { name: string }).name.split(
            " "
          );

          setIsloggedIn(true); // set this is redux store
          setLoggedIn(true); //set this in component state
          setUserAccess(1);
          setUserEmail(decodedToken["email"]);
          setFirstName(fname);
          setLastName(lname);
          setStoredAccessToken(token);
          setUserRole(authStub.userRole);
          setLoginType(authStub.loginType);
        }
        // We have auth code from idp server, generate auth token based on that.
        else if (codeMatch && codeMatch[1] && stateMatch && stateMatch[1] == getStateKey()) {
          setCodeToken(codeMatch[1]);

          //exchange the code received for access_token with the SSO provider
          authClient
            .getAccessTokenERM(codeMatch[1])
            .then((token: any) => {
              setStoredAccessToken(token.body["access_token"]);
              sessionStorage.setItem("isLoggedIn", "true");
              const decodedToken: any = getUserDetailsFromToken(
                token.body["access_token"]
              );

              const [fname, lname] = (
                decodedToken as { name: string }
              ).name.split(" ");

              setIsloggedIn(true); // set this is redux store
              setLoggedIn(true); //set this in component state
              setUserAccess(1);
              setUserEmail(decodedToken["email"]);
              setFirstName(fname);
              setLastName(lname);
              // setStoredAccessToken(token);
              setUserRole(token.body['role']);
              setLoginType(token.body['loginType']);
            })
            .catch((error) => {
              navigate("/accessdenied");
            });
        } else {
          let state = generateRandomValue();
          let nonce = generateRandomValue();
          // Store state and nonce parameters into the session, so we can retrieve them after
          // user will be redirected back with access token or code (since react state is cleared in this case)
          setStateKey(state);
          authClient.getCode(state);
        }
      } else {
        /**
         * authentication doesnt work on local , hence below properties needs to be set to make the app working on local.
         */
        // setCodeToken('')
        // setLoggedIn();
        // setUserId();
        // setUserAccess()
        // setIsloggedIn()
        // setUserEmail('')
        // sessionStorage.setItem('', '');
        // sessionStorage.setItem('', '')
        // setStoredAccessToken('')
      }

      window.history.replaceState({}, "", "/");
    }
  }, [loggedIn]);

  if (loggedIn) {
    return <Outlet data-testid="outlet" />;
  } else {
    return <CenteredLoadingSpinner />;
  }
};
