import * as request from 'superagent';
import { jwtDecode } from 'jwt-decode';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const STATE_KEY = 'STATE_KEY';
const NONCE_KEY = 'NONCE_KEY';
const CODE_TOKEN = 'CODE_TOKEN'

export const getStoredAccessToken = () =>
    sessionStorage.getItem(ACCESS_TOKEN_KEY);
export const setStoredAccessToken = (token: string) =>
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
export const removeStoredAccessToken = () =>
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);

export const getStateKey = () => sessionStorage.getItem(STATE_KEY);
export const setStateKey = (stateToken: string) =>
    sessionStorage.setItem(STATE_KEY, stateToken);
export const removeStateKey = () => sessionStorage.removeItem(STATE_KEY);

export const getCodeToken = () => sessionStorage.getItem(CODE_TOKEN);
export const setCodeToken = (codeToken: string) =>
    sessionStorage.setItem(CODE_TOKEN, codeToken);
export const removeCodeToken = () => sessionStorage.removeItem(CODE_TOKEN);

export const clearSessionKeys = () => {
    sessionStorage.removeItem(STATE_KEY);
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(NONCE_KEY);
    sessionStorage.removeItem('isLoggedIn')
    sessionStorage.removeItem('userEmail')
};

/**
 *
 * @returns decoded url with code or access_token
 */
export const parseHash = () => {
    return window.location.hash
        .replace('#', '')
        .split('&')
        .reduce((prev, item) => {
            return Object.assign(
                {
                    [item.toString().split('=')[0]]: decodeURIComponent(
                        item.toString().split('=')[1],
                    ),
                },
                prev,
            );
        }, {});
};

/**
 *
 * @returns random generated string to be used for auth requests
 */
export const generateRandomValue = () => {
    return Math.random().toString(36).substring(2, 12);
};

/**
 *
 * @param apiPath
 * @param headers
 * @param body
 * @returns {Promise<*>}
 */
export const post = (apiPath: string, headers: Record<string, string>, body = {}) =>
    new Promise((resolved, rejected) =>
        request
            .post(apiPath)
            .send(body)
            .set(headers)
            .end((err, res) => {
                if (err) {
                    rejected(res ? res.body : err);
                } else {
                    resolved(res.body);
                }
            }),
    );

/**
 *
 * @param apiPath
 * @param getBody
 * @param headers
 * @returns {Promise<*>}
 */
export const get = (apiPath: string, getBody = false, headers = {}) =>
    new Promise((resolved, rejected) =>
        request
            .get(apiPath)
            .set(headers)
            .end((err, res) => {
                if (err) {
                    rejected(res ? res.body : err);
                } else {
                    resolved(getBody ? res.body : res);
                }
            }),
    );

/**
 * function called on all api's to attach token in header.
 */
export const getAccessToken = () => {
    const accessToken = getStoredAccessToken();
    return accessToken;
};

export const getUserDetailsFromToken = (key: string, token: string) => {
    var decoded = jwtDecode(token) as any;
    return decoded[key];
};
