/**
 * @template CoreReducerState Type for properties Core reducer redux state.
 */

import { UserRole } from "@models/interfaces/user-roles";

interface CoreReducerState {
    loadingCount: number;
    loadingText: string;
    userProfile: any;
    showTab: boolean;
    isLoggedIn: boolean;
    userAccess: number;
    userId: number;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userRole: UserRole | null;
    loginType: string;
    accessToken: string;
}

/** Initial state of Core reducer redux state. */
export const initialState: CoreReducerState = {
    loadingCount: 0,
    loadingText: 'Processing...',
    userProfile: null,
    showTab: true,
    isLoggedIn: false,
    userAccess: 0,
    userId: null,
    userEmail: '',
    userFirstName: "",
    userLastName: "",
    userRole: null,
    loginType: "",
    accessToken: ""
};
