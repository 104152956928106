import { useDispatch, useSelector } from 'react-redux';
import {
    incrementLoader,
    decrementLoader,
    setUserProfile,
    setShowTab,
    setLoadingText,
    setIsloggedIn,
    setUserAccess,
    setUserId,
    setUserEmail,
    setUserRole,
    setLoginType,
    setAccessToken,
    setFirstName,
    setLastName
} from './core-reducer';
import { store } from '../redux-store-config';
import { UserRole } from '@models/interfaces/user-roles';

type RootState = ReturnType<typeof store.getState>;

/**
 * @function coreData
 * @description Custom hook that gives access to selectors and actions in coreReducer.
 * @return Object of all selectors and actions.
 */
export const coreData = () => {
    const dispatch = useDispatch();
    return {
        isLoading: useSelector(
            (state: RootState) => state.coreReducer.loadingCount > 0,
        ),
        userProfile: useSelector(
            (state: RootState) => state.coreReducer.userProfile,
        ),
        showTab: useSelector((state: RootState) => state.coreReducer.showTab),
        loadingText: useSelector(
            (state: RootState) => state.coreReducer.loadingText,
        ),
        isLoggedIn: useSelector(
            (state: RootState) => state.coreReducer.isLoggedIn,
        ),
        userAccess: useSelector(
            (state: RootState) => state.coreReducer.userAccess,
        ),
        userId: useSelector((state: RootState) => state.coreReducer.userId),
        userEmail: useSelector(
            (state: RootState) => state.coreReducer.userEmail,
        ),
        userFirstName: useSelector(
            (state: RootState) => state.coreReducer.userFirstName,
        ),
        userLastName: useSelector(
            (state: RootState) => state.coreReducer.userLastName,
        ),
        userRole: useSelector((state: RootState) => state.coreReducer.userRole),
        loginType: useSelector((state: RootState) => state.coreReducer.loginType),
        accessToken: useSelector((state: RootState) => state.coreReducer.accessToken),
        incrementLoader: (message: string) =>
            dispatch(incrementLoader(message)),
        decrementLoader: () => dispatch(decrementLoader()),
        setUserProfile: (userProfile: any) =>
            dispatch(setUserProfile(userProfile)),
        setShowTab: (showTab: boolean) => dispatch(setShowTab(showTab)),
        setLoadingText: (loadingText: string) =>
            dispatch(setLoadingText(loadingText)),
        setIsloggedIn: (isLoggedIn: boolean) =>
            dispatch(setIsloggedIn(isLoggedIn)),
        setUserAccess: (userAccess: number) =>
            dispatch(setUserAccess(userAccess)),
        setUserId: (userId: number) => dispatch(setUserId(userId)),
        setUserEmail: (userEmail: string) => dispatch(setUserEmail(userEmail)),
        setFirstName: (userFirstName: string) => dispatch(setFirstName(userFirstName)),
        setLastName: (userLastName: string) => dispatch(setLastName(userLastName)),

        setUserRole: (userRole: UserRole) => dispatch(setUserRole(userRole)),
        setLoginType: (loginType: string) => dispatch(setLoginType(loginType)),
        setAccessToken: (accessToken: string) => dispatch(setUserRole(accessToken)),
    };
};
