import { COREAPP_CONFIG_DIRECTORY } from "../../config";
import React from "react";
import {
  StyledDescription,
  StyledErrorContainer,
  StyledErrorTitle,
  StyledSpan,
} from "./styled";

const getErrorDetails = (type: string) => {
  switch (type) {
    case "accessDenied":
      return {
        dataTestId: "access-denied",
        title: COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG.ACCESS_DENIED_TITLE,
        description: (
          <>
            <span>
              {
                COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG
                  .ACCESS_DENIED_MESSAGE_ONE
              }
            </span>
            <span>
              {
                COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG
                  .ACCESS_DENIED_MESSAGE_TWO
              }
            </span>
          </>
        ),
      };
    case "error":
      return {
        dataTestId: "generic-error",
        title: COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG.API_ERROR_TITLE,
        description: (
          <>
            <span>
              {COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG.API_ERROR_MESSAGE}
            </span>
          </>
        ),
      };

    case "errorboundary":
      return {
        dataTestId: "error-boundary",
        title: COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG.ERROR_BOUNDARY_TITLE,
        description: (
          <>
            <span>
              {
                COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG
                  .ERROR_BOUNDARY_TITLE_MESSAGE
              }
            </span>
          </>
        ),
      };
    case "networkError":
      return {
        dataTestId: "network-error",
        title: COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG.NETWORK_ERROR_TITLE,
        description:
          COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG.NETWORK_ERROR_MESSAGE,
      };
    case "pageNotFound":
      return {
        dataTestId: "page-not-found",
        title: COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG.PAGE_NOT_FOUND_TITLE,
        description: (
          <>
            <span>
              {
                COREAPP_CONFIG_DIRECTORY.STATIC_TEXT_CONFIG
                  .PAGE_NOT_FOUND_MESSAGE_ONE
              }
            </span>
          </>
        ),
      };
  }
};

const generateErrorPage = (errorDetails: any) => (
  <StyledErrorContainer data-testid={errorDetails.dataTestId}>
    <StyledErrorTitle data-testid={`${errorDetails.dataTestId}-title`}>
      {errorDetails.title}
    </StyledErrorTitle>
    <StyledDescription data-testid={`${errorDetails.dataTestId}-description`}>
      {errorDetails.description}
    </StyledDescription>
  </StyledErrorContainer>
);

export const getErrorPage = (errorType: string) =>
  generateErrorPage(getErrorDetails(errorType));
