import { getAccessToken, getCodeToken } from '../auth/core-idp/utils/utils';
import { apiResponseInterface, RESPONSE_TYPES } from '../models';


type RequestMethod = "GET" | "POST" | "PUT" | "DELETE"


export class ServerError extends Error {
    code: number

    constructor(code: number, message: string) {
        super(message);
        this.code = code;
    }
}


export const apiRequest = async ({endpoint, successHandler, errorHandler, cleanUpHandler}: {
    endpoint: string,
    successHandler?: (response: apiResponseInterface) => void,
    errorHandler?: (error: Error) => void,
    cleanUpHandler?: () => void
  }) => {
    try {
        const resp = await apiWrapper.get(endpoint, null);
  
        if (resp.responseType === RESPONSE_TYPES.API_SUCCESS) {
          successHandler(resp);
        } else if (resp.responseType === RESPONSE_TYPES.API_FAILED) {

            const errorMessage = resp && typeof resp.responseData === "string" ?
                resp.responseData : "Generic error message";

          throw new ServerError(resp.responseCode, errorMessage)
        }
      } catch (error: any) {
        errorHandler(error)
      } finally {
        if (
          cleanUpHandler !== undefined &&
          typeof cleanUpHandler === "function"
        ) {
          cleanUpHandler();
        } else {
          console.log(`Running default clean up.`);
        }
      }
}


export const apiWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
};


function request(method: RequestMethod) {
    return async (url: string, body: any): Promise<apiResponseInterface> => {
        const URLwithCred = decorateURLWithCred(url)

        const requestOptions = {
            method,
            body,
        };

        const responseHandler: apiResponseInterface = {
            responseCode: 0,
            responseType: '',
            responseData: null,
        };

        try {
            const response = await fetch(URLwithCred, requestOptions);

            const data = await handleResponse(response, responseHandler);
                        
            return data;
        } catch (error) {
            responseHandler.responseCode = RESPONSE_TYPES.SERVER_ERROR_CODE;
            responseHandler.responseType = RESPONSE_TYPES.API_FAILED;
            responseHandler.responseData = null;

            throw error;

            // return Promise.reject(responseHandler);
        }
    };
}

// helper functions
function authHeader(url: string) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = getAccessToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_BASE_API_URL);
    if (isLoggedIn && isApiUrl) {
        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': `application/json`,
        };
    } else {
        return {};
    }
}


function decorateURLWithCred(url: string): string {
    const token = getAccessToken();
    const isLoggedIn = Boolean(token);
    const isApiUrl = url.startsWith(process.env.REACT_APP_BASE_API_URL);
    if (isLoggedIn && isApiUrl) {
        // Modify API request URL to include authoriztion token.

        const parsedURL = new URL(url);

        parsedURL.searchParams.append("Authorization", token)

        return parsedURL.toString();
    }

    return url;
}

async function handleResponse(response: any, responseHandler: any) {
    if (!response.ok) {
        const json = await response.json()

        
        responseHandler.responseType = RESPONSE_TYPES.API_FAILED;
        responseHandler.responseData = json.errorMessage
        
        switch (response.status) {
            case 400:
                responseHandler.responseCode =
                    RESPONSE_TYPES.NOT_AUTHORIZED_CODE;
                return responseHandler;
            case 401:
                responseHandler.responseCode =
                    RESPONSE_TYPES.NOT_AUTHORIZED_CODE;
                return responseHandler;
            case 403:
                responseHandler.responseCode =
                    RESPONSE_TYPES.NOT_AUTHENTICATED_CODE;
                return responseHandler;
            case 404:
                responseHandler.responseCode =
                    RESPONSE_TYPES.NOT_VALID_CODE;
                return responseHandler;
            case 500:
                responseHandler.responseCode =
                    RESPONSE_TYPES.SERVER_ERROR_CODE;
                return responseHandler;
            case 502:
                responseHandler.responseCode =
                    RESPONSE_TYPES.DATA_TOO_LARGE;
                return responseHandler;
            default:
                responseHandler.responseCode =
                    RESPONSE_TYPES.SERVER_ERROR_CODE;
                return responseHandler;
        }
    } else {
        return response.json().then((payload: any) => {
            responseHandler.responseCode = RESPONSE_TYPES.SUCCESS_CODE;
            responseHandler.responseType = RESPONSE_TYPES.API_SUCCESS;
            responseHandler.responseData = payload;
            return responseHandler;
        });
    }
}
