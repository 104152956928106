import { jwtDecode } from 'jwt-decode';

/**
 * @function getUserDetailsFromToken
 * @description Function that decodes the JWT and returns asked value
 * @param token JWT from the sessionStorage
 * @return Value of the key asked from the decoded token.
 */
export const getUserDetailsFromToken = (token: string) => {
    var decoded = jwtDecode(token);
    let returnValue = decoded
    return returnValue;
};
