import { RESPONSE_TYPES } from "@models/enums/response-types";
import { getErrorPage } from "@utils/error-pages-utility";
import React, { ReactElement, FC } from "react";

/**
 * @component AccessDenied
 * @description Displays error when access is denied to the user.
 * @returns AccessDenied
 * @example ```<AccessDenied />```
 */
export const AccessDenied: FC<any> = (): ReactElement => {
  return getErrorPage(RESPONSE_TYPES.ACCESS_DENIED);
};
