// Reference directory of all dynamic configs and final object.
// Any new configs created must be entered here.
// Developers can use this as reference point while developing and also as backup
// incase dynamic fetch fails

export const COREAPP_CONFIG_DIRECTORY = {
    STATIC_TEXT_CONFIG: {
        ACCESS_DENIED_TITLE: 'Access denied',
        ACCESS_DENIED_MESSAGE_ONE: 'You do not have access to this page.',
        ACCESS_DENIED_MESSAGE_TWO:
            'Follow departmental procedures to obtain access.',
        NETWORK_ERROR_TITLE: 'Network error',
        NETWORK_ERROR_MESSAGE:
            'Check your network cables, modem, and router or reconnect to Wi-Fi.',
        PAGE_NOT_FOUND_TITLE: 'Page not found',
        PAGE_NOT_FOUND_MESSAGE_ONE:
            'The link you followed is probably broken or the page has been removed.',
        ERROR_BOUNDARY_TITLE: 'Issues Loading Page',
        ERROR_BOUNDARY_TITLE_MESSAGE:
            'There seems to be issues loading this page. Please try again later or contact support.',
        PORTAL_TITLE: 'ERM',
        CONVERSATIONS_DEFAULT_TAB_NAME: '',
        API_ERROR_TITLE: 'Server Error',
        API_ERROR_MESSAGE:
            'The API server encountered an error. Please try again later or contact support if the problem persists.',
        LOGGED_OUT_SUCCESSFULLY: 'You have logged out successfully.',
    },

    ENV_OBJ: {
        2: 'stage',
        3: 'prod',
    },

    NOTICATION_RESET_AFTER_MS: 5000,

    TOAST_MESSAGES: {
        CLIENT_RESOURCE_EDIT_SUBMIT: 'Request has been processed',
        CLIENT_RESOURCE_RELOAD: 'Reload Request has been processed',
        CLIENT_CREATION_SUBMIT: 'Request has been processed',
        REQUEST_FAILURE: 'Request Failed',
        CLIENT_CREATE_SUCCESS: 'Client create successful',
        CLIENT_CREATE_FAILED: 'Client create failed',
    },

    REPLICAOPTIONS: [
        { id: 1, value: '1', label: '1' },
        { id: 2, value: '2', label: '2' },
        { id: 3, value: '3', label: '3' },
        { id: 4, value: '4', label: '4' },
        { id: 5, value: '5', label: '5' },
    ],

    REQUESTCPUOPTIONS: [
        { id: 1, value: '500m', label: '500m' },
        { id: 2, value: '1500m', label: '1500m' },
        { id: 3, value: '8000m', label: '8000m' },
    ],

    LIMITCPUOPTIONS: [
        { id: 1, value: '8', label: '8' },
        { id: 2, value: '10', label: '10' },
        { id: 3, value: '16', label: '16' },
    ],

    REQUESTMEMORYOPTIONS: [
        { id: 1, value: '500Mi', label: '500Mi' },
        { id: 2, value: '5000Mi', label: '5000Mi' },
        { id: 3, value: '10000Mi', label: '10000Mi' },
    ],

    LIMITMEMORYOPTIONS: [
        { id: 1, value: '10000Mi', label: '10000Mi' },
        { id: 2, value: '14000Mi', label: '14000Mi' },
        { id: 3, value: '35000Mi', label: '35000Mi' },
    ],

    CLIENTTYPEOPTIONS: [
        { id: 1, value: 'cm', label: 'cm' },
        { id: 2, value: 'ces', label: 'ces' },
    ],

    ENVOPTIONS: [
        { id: 1, value: 'dev', label: 'dev' },
        { id: 2, value: 'perf', label: 'perf' },
        { id: 3, value: 'stage', label: 'stage' },
        { id: 4, value: 'prod', label: 'prod' },
    ],

    LOCOPTIONS: [
        { id: 1, value: 'eastus', label: 'eastus' },
        { id: 2, value: 'centralus', label: 'centralus' },
    ],

    VARIATIONOPTIONS: [
        { id: 1, value: 'allinone', label: 'allinone' },
        { id: 2, value: 'separate', label: 'separate' },
    ],

    DBOPTIONS: [
        { id: 1, value: 'true', label: 'true' },
        { id: 2, value: 'false', label: 'false' },
    ],
};
