import React, {
  FC,
  FormEvent,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import {
  Button,
  FormControl,
  Dropdown,
  IDropdownItem,
  Checkbox,
  ITableData,
  Label,
  Table,
  TextInput,
  Heading,
  Card,
  Footer,
  Dialog,
  Text,
  HelperText,
  Legend as Legend2,
  Fieldset as Fieldset2,
} from "@uitk/react";
import styled, { css } from "styled-components";
import { RESPONSE_TYPES, apiResponseInterface } from "@models/index";
import { apiWrapper } from "@services/api-wrapper";
import { coreData } from "@store/index";
import { useNavigate } from "react-router";
import { current } from "@reduxjs/toolkit";

const AppContent = styled.div`
  margin: 60px 32px 24px;
`;

const CurrentRouteWrapper = styled.div`
  padding: 12px 0;
`;

const CurrentRoute = styled.span`
  margin-left: 8px;
`;

interface FilterValues {
  [key: string]: string;
}

const TextInputWrapper = styled.div`
  ${({ theme }) => css`
    flex-direction: row;
    padding-right: ${theme.UitkTableSpacingM};
    flex-wrap: wrap;
    @media (min-width: 720px) {
      display: flex;
    }
  `}
`;

const TextInputContainer = styled.div`
  ${({ theme }) => css`
    padding-right: ${theme.UitkTableSpacingL};
    flex-direction: column;
    margin-top: ${theme.UitkTableSpacingL};
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterPanel = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.UitkTableColorBackgroundRest};
    padding: ${theme.UitkTableSpacingL};
    border-top: 4px solid ${theme.UitkTableColorBorderHeadingRest};
  `}
`;

const CaptionDescription = styled.span`
  ${({ theme }) => css`
    display: block;
    color: ${theme.UitkTableColorTextHelperText};
    font-family: ${theme.UitkTableFontFamilyHelperText};
    font-weight: ${theme.UitkTableFontWeightHelperText};
    font-size: ${theme.UitkTableFontSizeHelperText};
    line-height: ${theme.UitkTableLineHeightHelperText};
    text-decoration: ${theme.UitkTableTextDecorationHelperText};
  `}
`;

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

const Legend = styled.legend`
  ${({ theme }) => css`
    font-family: ${theme.UitkTableFontFamilyHeading};
    font-weight: ${theme.UitkTableFontWeightHeading};
    font-size: ${theme.UitkTableSizingS};
    line-height: ${theme.UitkTableLineHeightBody};
  `}
`;
const TableHeading = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.UitkTableFontFamilyHeading};
    font-weight: ${theme.UitkTableFontWeightHeading};
    line-height: ${theme.UitkTableLineHeightHeading};
    text-decoration: ${theme.UitkTableTextDecorationHeading};
    margin: ${theme.UitkSemanticSpacingBase} ${theme.UitkSemanticSpacingNone};
  `}
`;

const linkConfig = {
  links: [
    {
      href: "https://www.optum.com/about-us.html",
      label: "About Us",
      openInNewTab: true,
    },
    {
      href: "https://www.optum.com/contact-us.html",
      label: "Contact Us",
      openInNewTab: true,
    },
    {
      href: "https://www.optum.com/more-optum-sites.html",
      label: "More Optum Sites",
      openInNewTab: true,
    },
  ],
};

const logo = (
  <img
    src="https://www.optum.com/content/dam/optum4/images/logos/optum-logo-ora-rgb1.svg"
    className="uitk-footer__logo--small uitk-footer__logo--medium uitk-footer__logo--large"
    alt="logo"
  />
);

const VisuallyHidden = styled.span`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const currentYear = new Date().getFullYear();

let dataSource = [
  {
    id: "1",
    userEmail: "EMAIL",
    firstName: "",
    lastName: "",
    roleID: 1,
    modifiedDate: "2022-10-13T14:23:04.173",
    modifiedBy: "Prasad Parsa",
    createdDate: "2022-05-17T21:19:06.397",
    createdBy: "Admin",
    isActive: 1,
  },
];

export const Administration: FC = () => {
  const [loadedData, setLoadedData] = useState(false);
  const { userRole } = coreData();

  const makeAPICall = async (
    endpoint: string,
    successHandler: (response: apiResponseInterface) => void
  ) => {
    try {
      const resp = await apiWrapper.get(endpoint, null);

      if (resp.responseType === RESPONSE_TYPES.API_SUCCESS) {
        successHandler(resp);
      } else if (resp.responseType === RESPONSE_TYPES.API_FAILED) {
        switch (resp.responseCode) {
          case 401:
            // navigate("/Logout");
            break;
        }
      }
    } catch (error) {
      console.error("error handler arm...", error);
    } finally {
      setLoadedData(true);
    }
  };

  const apiURL = `${process.env.REACT_APP_BASE_API_URL}`;

  async function fetchUsers() {
    const dataLink = `${apiURL}/api/v1/erm/users`;

    await makeAPICall(dataLink, (resp) => {
      const result = resp.responseData;
      dataSource = result;
      for (let i = 0; i < dataSource.length; i++) {
        if (dataSource[i].roleID === 1) {
          dataSource[i].createdBy = "Admin";
        } else {
          dataSource[i].createdBy = "User";
        }
      }
      applyFilters();
      setLoadedData(true);
    });
  }

  async function deleteUsers(a : any){
    const b = a.replace("@", "%40")
    const d = sessionStorage.getItem("ACCESS_TOKEN")
    let dataLink = `${apiURL}/api/v1/erm/user/status/update?${d}` + "&userEmail=" + b;
    await makeAPICall(dataLink, (resp) => {
      const result = resp.responseData;
    });
  }

  async function onSubmitPressedAction() {
    const e  = sessionStorage.getItem("ACCESS_TOKEN");
    const d = email;
    const userData = {
      userEmail: email,
      roleID: selectedItem.value,
      firstName: name,
      lastName: address
    }
    const dataLink = `${apiURL}/api/v1/erm/user?Authorization=${e}`
    await fetch(dataLink, {
      method: "POST",
      headers: {'Content-Type': 'application/json'}, 
      body: JSON.stringify(userData)
    }).then(res => {
      alert("Success, User has been added. Refresh page to see changes");
      navigate("/Administration")
    });
    setOpenDialog(false);
    resetFormFields();
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSortFunction = (isAscending: boolean, sortColumnLabel: string) => {
    setCaptionDescription(
      isAscending
        ? "Column " + sortColumnLabel + " is sorted in ascending order"
        : "Column " + sortColumnLabel + " is sorted in descending order"
    );
  };
  const [openModal, setOpenModal] = useState(false);
  const dismissModal = () => setOpenModal(false);
  const [currentID, setCurrentID] = useState("");

  const handleDelete = (currentID: string) => {
    
    const numberID = parseInt(currentID);
    
    for(let i = 0; i < dataSource.length; i=i+1) {
      if(numberID === parseInt(dataSource[i].id)) {
        const c = dataSource[i].userEmail;
        deleteUsers(c);
        setData((prevData) => prevData.filter(({ id }) => id !== currentID));
      }
    }
    setOpenModal(false);
  };
  const onRenderCell = ({ id }: ITableData) => (
  <>
      <Button variant="secondary" onPress={function() {
        setCurrentID(id);
        setOpenModal(true);
        }}>
        Remove Status
        <VisuallyHidden>Opens Modal Window</VisuallyHidden>
      </Button>
  </>
  );

  const config = {
    columns: [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "userEmail" },
      { label: "Role", key: "createdBy" },
      { label: "Status", onRenderCell },
    ],
    sort: true,
    sticky: { headerSticky: true },
    onSort: onSortFunction,
    labelledBy: "table-caption",
  };

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [data, setData] = useState(dataSource);
  const [captionDescription, setCaptionDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [filtered, setFiltered] = useState(false);
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
  } as FilterValues);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const dismiss = () => setOpenDialog(false);
  const onSubmitPressed = () => onSubmitPressedAction();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [nameBlurred, setNameBlurred] = useState(false);
  const [addressBlurred, setAddressBlurred] = useState(false);
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [blurred, setBlurred] = useState(false);
  const dropDownError = !selectedItem && blurred ? "Dropdown is required" : "";

  const nameError =
    name.length === 0 && (nameBlurred || submitted) ? "Name is required" : "";
  const addressError =
    address.length === 0 && (addressBlurred || submitted)
      ? "Address is required"
      : "";
  const emailError =
    email.length === 0 && (emailBlurred || submitted)
      ? "Email is required"
      : "";
  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    setSubmitted(true);
    event.preventDefault();
  };

  const toggleOpen = () => setOpen((curr) => !curr);

  const applyFilters = () => {
    const filterData = dataSource
      .filter((row) => {
        return row.firstName
          .toLowerCase()
          .includes(value.firstName.toLowerCase());
      })
      .filter((row) => {
        return row.lastName
          .toLowerCase()
          .includes(value.lastName.toLowerCase());
      })
      .filter((row) => {
        return row.userEmail
          .toLowerCase()
          .includes(value.userEmail.toLowerCase());
      })
      .filter((row) => {
        return row.createdBy
          .toLowerCase()
          .includes(value.userEmail.toLowerCase());
      });
    // .filter(row => {
    //   return row.ssn.toLowerCase().includes(value.ssn.toLowerCase());  use incase of using numbers aka dates
    // });
    setData(filterData);
  };

  const dropDownItems: IDropdownItem[] = [
    { id: "1", label: "Admin", value: 1 },
    { id: "2", label: "User", value: 2 },
  ];

  const findFilterLength = () => {
    let filterCount = 0;
    ["firstName", "lastName", "userEmail", "createdBy"].forEach((column) => {
      if (value[column].length > 0) {
        filterCount++;
      }
    });
    return filterCount;
  };

  const onFilter = () => {
    applyFilters();
    setFilterCount(findFilterLength());
    setFiltered(true);
  };

  const reset = () => {
    setValue({
      firstName: "",
      lastName: "",
      userEmail: "",
      createdBy: "",
    });
    setData(dataSource);
    setFilterCount(0);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const getCaptionDescription = () => {
    const displayedString =
      "Displaying " +
      data.length +
      " of " +
      dataSource.length +
      " rows of the table. ";
    return captionDescription
      ? displayedString + captionDescription
      : filtered
      ? displayedString
      : "The table has " + dataSource.length + " rows.";
  };

  const filterInputs = config.columns.map((column) => {
    return (
      <TextInputContainer key={column.key}>
        <FormControl id={"text-input-" + column.key}>
          {<Label id={"text-input-" + column.key}>{column.label}</Label>}
          {column.label && (
            <TextInput
              type={"text"}
              name={column.key}
              onChange={handleChange}
              value={value[column.key]}
            />
          )}
        </FormControl>
      </TextInputContainer>
    );
  });

  function resetFormFields() {
    setValue({
      name: "",
      address: "",
      email: "",
    });
    setName("");
    setAddress("");
    setEmail("");
    setNameBlurred(false);
    setAddressBlurred(false);
    setEmailBlurred(false);
    setSubmitted(false);
  }

  return userRole !== "Admin" ? (
    <Dialog title="Unauthorized Access" onClose={() => navigate("/")}>
      <Dialog.Body>
        <Text>Only users with a role of "Admin" can access this page!.</Text>
      </Dialog.Body>

      <Dialog.Actions>
        <Button onPress={() => navigate("/")}>Ok</Button>
      </Dialog.Actions>
    </Dialog>
  ) : (
    <div style={{minHeight:"70vh"}}>
      <AppContent>
        <main tabIndex={-1} id="main">
          <Heading>Administration</Heading>
        </main>
      </AppContent>
      <Card>
        <Table data={data} config={config} className={"pt-b"} />
        <Button onPress={() => setOpenDialog(true)} className="btnMoved">
          Create User
          <VisuallyHidden>Opens Modal Window</VisuallyHidden>
        </Button>
        {openModal && (
        <Dialog open={open} title="Remove Status from user?" titleAs="h1" onClose={dismissModal}>
          <Dialog.Body>
            <Text>
              Are you sure you want to remove status this user? This action cannot be
              undone.
            </Text>
          </Dialog.Body>
          <Dialog.Actions>
            <Button onPress={() => handleDelete(currentID)}>Remove Status</Button>
            <Button variant="secondary" onPress={dismissModal}>
              Cancel
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
        {openDialog && (
          <Dialog
            open={openDialog}
            title="Create User"
            titleAs="h1"
            onClose={dismiss}
          >
            <Dialog.Body>
              <Fieldset2>
                <Legend2>
                  <HelperText>
                    All fields marked with an asterisk (
                    <span className="uitk-asterisk"></span>) are required
                  </HelperText>
                </Legend2>
                <form onSubmit={onSubmit}>
                  <FormControl
                    id="required-text-input-1"
                    error={nameError}
                    required
                  >
                    <Label>First Name</Label>
                    <TextInput
                      onChange={(f: ChangeEvent<HTMLInputElement>) =>
                        setName(f.target.value)
                      }
                      onBlur={() => setNameBlurred(true)}
                      value={name}
                      style={{ width: "90%", margin: "10px" }}
                    />
                  </FormControl>
                  <FormControl
                    id="required-text-input-2"
                    error={addressError}
                    required
                  >
                    <Label>Last Name</Label>
                    <TextInput
                      onChange={(f: ChangeEvent<HTMLInputElement>) =>
                        setAddress(f.target.value)
                      }
                      onBlur={() => setAddressBlurred(true)}
                      value={address}
                      style={{ width: "90%", margin: "10px" }}
                    />
                  </FormControl>
                  <FormControl
                    id="required-text-input-3"
                    error={emailError}
                    required
                  >
                    <Label>Email</Label>
                    <TextInput
                      onChange={(f: ChangeEvent<HTMLInputElement>) =>
                        setEmail(f.target.value)
                      }
                      onBlur={() => setEmailBlurred(true)}
                      value={email}
                      style={{ width: "90%", margin: "10px" }}
                    />
                  </FormControl>
                  <FormControl
                    id="dropdown-required"
                    required
                    error={dropDownError}
                    className="dropDowncss"
                  >
                    <Label>User Role</Label>
                    <Dropdown
                      type="single"
                      items={dropDownItems}
                      value={selectedItem}
                      onChange={setSelectedItem}
                      onBlur={() => setBlurred(true)}
                    />
                  </FormControl>
                </form>
              </Fieldset2>
            </Dialog.Body>
            <Dialog.Actions>
              <Button onPress={onSubmitPressed}>Create User</Button>
              <Button variant="secondary" onPress={dismiss}>
                Cancel
              </Button>
            </Dialog.Actions>
          </Dialog>
        )}
      </Card>
    </div>
  );
};