import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './core-state'
/**
 * @function coreReducerSlice
 * @description Reducer for all Core related redux actions.
 */
export const coreReducerSlice: any = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        incrementLoader: (state, action: PayloadAction<string>) => {
            state.loadingCount += 1;
            state.loadingText = action.payload
        },
        decrementLoader: (state) => {
            state.loadingCount > 0 ? state.loadingCount -= 1 : 0;
            state.loadingText = ''
        },
        setUserProfile: (state, action: PayloadAction<any>) => {
            state.userProfile = action.payload;
        },
        setShowTab: (state, action: PayloadAction<any>) => {
            state.showTab = action.payload;
        },
        setLoadingText: (state, action: PayloadAction<any>) => {
            state.loadingText = action.payload;
        },
        setIsloggedIn: (state, action: PayloadAction<any>) => {
            state.isLoggedIn = action.payload;
        },
        setUserAccess: (state, action: PayloadAction<any>) => {
            state.userAccess = action.payload;
        },
        setUserId: (state, action: PayloadAction<any>) => {
            state.userId = action.payload;
        },
        setUserEmail: (state, action: PayloadAction<any>) => {
            state.userEmail = action.payload;
        },
        setFirstName: (state, action: PayloadAction<any>) => {
            state.userFirstName = action.payload
        },
        setLastName: (state, action: PayloadAction<any>) => {
            state.userLastName = action.payload
        },
        setUserRole: (state, action: PayloadAction<any>) => {
            state.userRole = action.payload
        },
        setLoginType: (state, action: PayloadAction<any>) => {
            state.loginType = action.payload
        },
        setAccessToken: (state, action: PayloadAction<any>) => {
            state.accessToken = action.payload
        }
    },
});

export const {
    incrementLoader,
    decrementLoader,
    setUserProfile,
    setShowTab,
    setLoadingText,
    setIsloggedIn,
    setUserAccess,
    setUserId,
    setUserEmail,
    setFirstName,
    setLastName,
    setUserRole,
    setLoginType,
    setAccessToken
} = coreReducerSlice.actions;
