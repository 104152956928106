import { RESPONSE_TYPES } from "@models/enums/response-types";
import { FC, ReactElement } from "react";
import { getErrorPage } from "@utils/error-pages-utility";

/**
 * @component ErrorPage
 * @description Displays error when api is down or some generic error occurs.
 * @returns ErrorPage
 * @example ```<ErrorPage />```
 */
export const ErrorPage: FC<any> = (): ReactElement => {
  return getErrorPage(RESPONSE_TYPES.ERROR);
};
