import React, { createContext, useContext, useState } from 'react';

interface PageVisitedEntry {
  path: string;
  title: string;
  summary: string;
}

interface PageVisitedContextValue {
  recentlyViewed: PageVisitedEntry[];
  handlePageVisited: (entry: PageVisitedEntry) => void;
}

const PageVisitContext = createContext<PageVisitedContextValue>({
  recentlyViewed: [],
  handlePageVisited: () => {},
});

export const PageVisitedProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [recentlyViewed, setRecentlyViewed] = useState<PageVisitedEntry[]>([
    {
      path: "/PayorDeny",
      title: "Pay or Deny",
      summary: "Review edit rationale for pay and/or deny codes"
    },
    {
      path: "/Modifier",
      summary: "Review edit for valid modifier/procedure code",
      title: "Modifier"
    },
    {
      path: "/GlobalDays",
      title: "Global Days",
      summary: "Review edit rationale for procedure global days"
    },
    {
      path: "/PlaceofService",
      title: "Place of Service",
      summary: "Review edit rationale for valid places of service"
    }
  ]);
  const handlePageVisited = (entry: PageVisitedEntry) => {
    const updatedRecentlyViewed = [entry, ...recentlyViewed.filter(e => e.path !== entry.path)].slice(0, 4);
    setRecentlyViewed(updatedRecentlyViewed);
    localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
  };

  return (
    <PageVisitContext.Provider value={{ recentlyViewed, handlePageVisited }}>
      {children}
    </PageVisitContext.Provider>
  );
};

export const usePageVisit = () => useContext(PageVisitContext);