import React from "react";
import { ReduxProvider } from "../store/redux-store-config";
import { BrowserRouter as Router } from "react-router-dom";
import { PageVisitedProvider } from "./Welcome";
import { WorkSpace } from "./WorkSpace";
import { createGlobalStyle } from "styled-components";

import "@uitk/foundations/Brand_optum/fonts.css";
import "@uitk/foundations/Brand_uhc/fonts.css";
import { optum } from "@uitk/foundations";

import { Toolkit } from "@uitk/react";

const GlobalStyles = createGlobalStyle`
  html, body { margin: 0; padding: 0; }
  html, body, #react-root { height: 100%;}
`;

export function App() {
  return (
    <ReduxProvider>
      <Router>
        <PageVisitedProvider>
          <GlobalStyles />
          <Toolkit appId="@uitk/react-starter-kit" grid spacing theme={optum}>
            <WorkSpace />
          </Toolkit>
        </PageVisitedProvider>
      </Router>
    </ReduxProvider>
  );
}
