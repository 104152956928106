import React, { useEffect, useState, ChangeEvent } from "react";
import { NavigationLinkProps } from "@uitk/react/types";
import { createGlobalStyle } from "styled-components";
import {
  Label,
  Heading,
  Text,
  Button,
  Panel,
  Card,
  LoadingIndicator,
  Footer,
  getNativeProps,
  anchorProperties,
} from "@uitk/react";
import styled from "styled-components";
import "../../PayorDeny/PayorDenyStyles.css";
import { Link as LinkPages, useNavigate } from "react-router-dom";

import "./GlobalDaysStyles.css";
import { useLocation } from "react-router-dom";
import { usePageVisit } from "../../Welcome/PageVisited";
import { apiResponseInterface, RESPONSE_TYPES } from "@models/index";
import { apiWrapper } from "@services/api-wrapper";

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  html, body, #react-root {
    height: 100%;
    min-height: 1500px;
  }
`;

const OverlayWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const StoryWrapperNavBar = styled.div`
  position: absolute;
  height: 1000px;
  width: 20%;
  background-color: aqua;
  transition: width 0.8s ease-in-out;
  .openNavBar {
    width: 0%;
  }
`;

const StoryWrapper = styled.div`
  background-color: ${(props) => props.theme.UitkSemanticColorBgCanvas};
  padding: 10px 20px 20px 20px;
  display: flex;
  .uitk-button {
    margin-right: 15px;
    margin-top: 10px;
  }
  .displayPageNumber {
    margin-top: 25px;
    margin-right: 10px;
  }
`;

const StoryWrapperGrid = styled.div`
  .demo-content {
    border-left: 1px solid #888b8d;
    color: #000000;
    margin: 0px 0px: 0px: 0px;
  }
`;

const StoryWrapperSideContent = styled.div`
  .searchBox {
    border-top: 4px solid rgba(255, 97, 43, 1);
    outline: 1px solid rgba(255, 255, 255, 1);
    height: auto;
  }

  // .item1 {
  //   width: 50%
  // }
  .topGap40 {
    margin-top: 20px;
  }
  .panel2 {
    color: rgb(75, 77, 79);
    line-height: 1.5rem;
    font-size: 1.125rem;
    text-decoration: none;
  }
`;

const StoryWrapperBreadCrumb = styled.div`
  .breadcrumbHome {
    color: rgba(54, 123, 245, 1);
    height: auto;
    text-decoration: none;
  }
  .breadcrumbHome:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  `;
export const GlobalDays: React.FC = () => {
  const { handlePageVisited } = usePageVisit();
  const currentRoute = useLocation();

  useEffect(() => {
    handlePageVisited({
      path: currentRoute.pathname,
      title: "Global Days",
      summary: "Review edit rationale for procedure global days",
    });
  }, []);
  const [zIndexLoading, setzIndexLoading] = useState(-1);

  const RoutableLink: React.FC<NavigationLinkProps> = (item) => {
    const { url, children } = item;
    const anchorProps = getNativeProps(item, anchorProperties);
    return (
      <LinkPages to={url} {...anchorProps}>
        {children}
      </LinkPages>
    );
  };

  const [loading, setLoading] = useState(false);

  const logo = (
    <img
      src="https://www.optum.com/content/dam/optum4/images/logos/optum-logo-ora-rgb1.svg"
      className="uitk-footer__logo--small uitk-footer__logo--medium uitk-footer__logo--large"
      alt="logo"
    />
  );
  const linkConfig = {
    links: [
      {
        href: "https://www.optum.com/about-us.html",
        label: "About Us",
        openInNewTab: true,
      },
      {
        href: "https://www.optum.com/contact-us.html",
        label: "Contact Us",
        openInNewTab: true,
      },
      {
        href: "https://www.optum.com/more-optum-sites.html",
        label: "More Optum Sites",
        openInNewTab: true,
      },
    ],
  };

  const currentDate = new Date().toISOString().slice(0, 10);
  const currentYear = new Date().getFullYear();

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [code1, setCode1] = useState("");
  const [dateCode, setDateCode] = useState(currentDate);

  const handleCode1 = (event: ChangeEvent<HTMLInputElement>) => {
    setCode1(event.target.value);
  };

  const handleDateCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateCode(event.target.value);
  };

  let [informationGrids, setInformationGrids] = useState([
    <>
      <div className="dataGridContainer-colums4">
        <div className="dataGrid boldCell">Procedure Code:</div>
        <div className="dataGrid">
          <span id="code1Begin"></span>
        </div>
        <div className="dataGrid boldCell">Description:</div>
        <div className="dataGrid">
          <span id="code1BeginDesc"></span>
        </div>
      </div>
      <div className="dataGridContainer-colums2">
        <div className="dataGrid boldCell">Global Days:</div>
        <div className="dataGrid">
          <span id="code1BeginDesc"></span>
        </div>
      </div>
      <div className="dataGridContainer-colums4">
        <div className="dataGrid boldCell">Effective Start Date:</div>
        <div className="dataGrid">
          <span id="effStartDate"></span>
        </div>
        <div className="dataGrid boldCell">Effective End Date:</div>
        <div className="dataGrid">
          <span id="effEndDate"></span>
        </div>
      </div>
    </>,
  ]);

  let [sourcesGrids, setSourcesGrids] = useState([
    <>
      <div className="dataGrid boldCell dataTableTitle">Source</div>
      <div className="dataGrid boldCell dataTableTitle">Source Year</div>
      <div className="dataGrid boldCell dataTableTitle">Source Month</div>
      <div className="dataGrid boldCell dataTableTitle">Additional Info.</div>
      <div className="dataGrid boldCell dataTableTitle">
        Effective Start Date
      </div>
      <div className="dataGrid boldCell dataTableTitle">Effective End Date</div>
    </>,
  ]);

  let [editRationaleStatementText, setEditRationaleStatementText] =
    useState("");

  let [beginPageNumberDisplay, setBeginPageNumberDisplay] = useState("XX");
  let [endPageNumberDisplay, setEndPageNumberDisplay] = useState("XX");
  var [beginPageNumber, setBeginPageNumber] = useState(1);
  var [endPageNumber, setEndPageNumber] = useState<number | undefined>(1);

  let sourceGrids: JSX.Element[] = [];

  let [viewAllCodesGrids, setViewAllCodesGrids] = useState<JSX.Element[]>([]);
  let updateContent: JSX.Element[] = [];
  let [textElement, setTextElement] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState("");
  type ErrorMessage = {
    title: string;
    details: string;
    action: {
      label: string;
      handler: () => void;
    };
  };
  const [error, setError] = useState<ErrorMessage | null>(null);

  const apiURL = `${process.env.REACT_APP_BASE_API_URL}`;

  const navigate = useNavigate();
  const makeAPICall = async (
    endpoint: string,
    successHandler: (response: apiResponseInterface) => void
  ) => {
    try {
      const resp = await apiWrapper.get(endpoint, null);

      if (resp.responseType === RESPONSE_TYPES.API_SUCCESS) {
        successHandler(resp);
      } else if (resp.responseType === RESPONSE_TYPES.API_FAILED) {
        if(resp.responseData === "JWT verification failed"){
          setLoading(false);
          setzIndexLoading(-1);
          setErrorDisplay(resp.responseData);
          setTimeout(function() {
            navigate("/");
          }, 2000);
        }
        else {
          setErrorDisplay(resp.responseData);
          setLoading(false);
          setzIndexLoading(-1);
        }
      }
    } catch (error) {
      console.error("error handler arm...", error);
      setErrorDisplay("Gateway timeout, data may be too big");
      setLoading(false);
      setzIndexLoading(-1);
    } finally {
      // setLoading((curr) => !curr);
      // setzIndexLoading(-1);
    }
  };

  async function viewAllCodes() {
    setErrorDisplay("");
    const dataLink =
      `${apiURL}/api/v1/erm/storedproc/followUpDaysProcedure/list?Procedurecode=` +
      code1 +
      "&Querydate=" +
      dateCode +
      "&start=1";
    await makeAPICall(dataLink, (resp) => {
      const resultVAC = resp.responseData;
      if (resultVAC.length > 8) {
        setTextElement(
          <Label style={{ float: `right` }}>
            {resultVAC.length} results found
          </Label>
        );
      }
      for (let i = 0; i < resultVAC.length; i++) {
        let j = i * 3 + 1;
        const k = resultVAC[i].PROCEDURE_CODE;
        const l = resultVAC[i].POS_CODE;
        updateContent.push(
          <>
            <div
              key={j}
              onClick={() => loadPanelData(k, l, dateCode, i + 1)}
              className="cursorHover linkColor"
            >
              <span>{i + 1}</span>
            </div>
            <div
              key={j + 1}
              onClick={() => loadPanelData(k, l, dateCode, i + 1)}
              className="cursorHover"
            >
              {resultVAC[i].PROCEDURE_CODE}
            </div>
          </>
        );
      }
      setViewAllCodesGrids(updateContent);
    });
  }

  async function pageUp() {
    setLoading((curr) => !curr);
    setzIndexLoading(10);
    beginPageNumber++;
    setBeginPageNumber(beginPageNumber); //this updates the page count to display on UI

    const dataLink =
      `${apiURL}/api/v1/erm/storedproc/followUpDaysProcedure?Procedurecode=` +
      code1 +
      "&Querydate=" +
      dateCode +
      "&start=" +
      beginPageNumber +
      "&size=1";
    await makeAPICall(dataLink, (resp) => {
      let result = resp.responseData;

      let updatedInformationGrids = [
        <>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Procedure Code:</div>
            <div className="dataGrid">
              <span id="code1Begin">{result.reponse[0][0].PROCEDURE_CODE}</span>
            </div>
            <div className="dataGrid boldCell">Description:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].PROCEDURE_CODE_DESCRIPTION}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums2">
            <div className="dataGrid boldCell">Global Days:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].FOLLOW_UP_DAYS}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Effective Start Date:</div>
            <div className="dataGrid">
              <span id="effStartDate">
                {result.reponse[0][0].EFFECTIVE_DATE}
              </span>
            </div>
            <div className="dataGrid boldCell">Effective End Date:</div>
            <div className="dataGrid">
              <span id="effEndDate">
                {result.reponse[0][0].EFFECTIVE_EXPIRATION_DATE}
              </span>
            </div>
          </div>
        </>,
      ];
      setInformationGrids(updatedInformationGrids);

      for (let i = 0; i < result.reponse[0].length; i++) {
        let j = i * 6 + 1;
        sourceGrids.push(
          <>
            <div className="dataGrid" key={j}>
              {result.reponse[0][i].SOURCE_CODE}
            </div>
            <div className="dataGrid" key={j + 1}>
              {result.reponse[0][i].KB_SOURCE_YEAR}
            </div>
            <div className="dataGrid" key={j + 2}>
              {result.reponse[0][i].KB_SOURCE_MONTH}
            </div>
            <div className="dataGrid" key={j + 3}>
              {result.reponse[0][i].KB_SOURCE_INFO}
            </div>
            <div className="dataGrid" key={j + 4}>
              {result.reponse[0][i].EDIT_SOURCE_EFFECTIVE_DATE}
            </div>
            <div className="dataGrid" key={j + 5}>
              {result.reponse[0][i].EDIT_SOURCE_EXPIRATION_DATE}
            </div>
          </>
        );
      }
      let updatedSourcesGrids = [
        <>
          <div className="dataGrid boldCell dataTableTitle">Source</div>
          <div className="dataGrid boldCell dataTableTitle">Source Year</div>
          <div className="dataGrid boldCell dataTableTitle">Source Month</div>
          <div className="dataGrid boldCell dataTableTitle">
            Additional Info.
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective Start Date
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective End Date
          </div>
        </>,
        ...sourceGrids,
      ];
      setSourcesGrids(updatedSourcesGrids);

      let descStatement = result.reponse[0][0].DISCLOSURE;
      descStatement = descStatement.replaceAll("\r\n", "\n");
      setEditRationaleStatementText(descStatement);

      setLoading((curr) => !curr);
      setzIndexLoading(-1);
    });
  }

  async function pageDown() {
    setLoading((curr) => !curr);
    setzIndexLoading(10);
    beginPageNumber--;
    setBeginPageNumber(beginPageNumber); //this updates the page count to display on UI

    const dataLink =
      `${apiURL}/api/v1/erm/storedproc/followUpDaysProcedure?Procedurecode=` +
      code1 +
      "&Querydate=" +
      dateCode +
      "&start=" +
      beginPageNumber +
      "&size=1";
    await makeAPICall(dataLink, (resp) => {
      let result = resp.responseData;

      let updatedInformationGrids = [
        <>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Procedure Code:</div>
            <div className="dataGrid">
              <span id="code1Begin">{result.reponse[0][0].PROCEDURE_CODE}</span>
            </div>
            <div className="dataGrid boldCell">Description:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].PROCEDURE_CODE_DESCRIPTION}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums2">
            <div className="dataGrid boldCell">Global Days:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].FOLLOW_UP_DAYS}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Effective Start Date:</div>
            <div className="dataGrid">
              <span id="effStartDate">
                {result.reponse[0][0].EFFECTIVE_DATE}
              </span>
            </div>
            <div className="dataGrid boldCell">Effective End Date:</div>
            <div className="dataGrid">
              <span id="effEndDate">
                {result.reponse[0][0].EFFECTIVE_EXPIRATION_DATE}
              </span>
            </div>
          </div>
        </>,
      ];
      setInformationGrids(updatedInformationGrids);

      for (let i = 0; i < result.reponse[0].length; i++) {
        let j = i * 6 + 1;
        sourceGrids.push(
          <>
            <div className="dataGrid" key={j}>
              {result.reponse[0][i].SOURCE_CODE}
            </div>
            <div className="dataGrid" key={j + 1}>
              {result.reponse[0][i].KB_SOURCE_YEAR}
            </div>
            <div className="dataGrid" key={j + 2}>
              {result.reponse[0][i].KB_SOURCE_MONTH}
            </div>
            <div className="dataGrid" key={j + 3}>
              {result.reponse[0][i].KB_SOURCE_INFO}
            </div>
            <div className="dataGrid" key={j + 4}>
              {result.reponse[0][i].EDIT_SOURCE_EFFECTIVE_DATE}
            </div>
            <div className="dataGrid" key={j + 5}>
              {result.reponse[0][i].EDIT_SOURCE_EXPIRATION_DATE}
            </div>
          </>
        );
      }
      let updatedSourcesGrids = [
        <>
          <div className="dataGrid boldCell dataTableTitle">Source</div>
          <div className="dataGrid boldCell dataTableTitle">Source Year</div>
          <div className="dataGrid boldCell dataTableTitle">Source Month</div>
          <div className="dataGrid boldCell dataTableTitle">
            Additional Info.
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective Start Date
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective End Date
          </div>
        </>,
        ...sourceGrids,
      ];
      setSourcesGrids(updatedSourcesGrids);

      let descStatement = result.reponse[0][0].DISCLOSURE;
      descStatement = descStatement.replaceAll("\r\n", "\n");
      setEditRationaleStatementText(descStatement);

      setLoading((curr) => !curr);
      setzIndexLoading(-1);
    });
  }

  async function testApi() {
    setLoading((curr) => !curr);
    setzIndexLoading(10);
    setTextElement(null);
    viewAllCodes();
    beginPageNumber = 1;
    setBeginPageNumber(1);

    const dataLink =
      `${apiURL}/api/v1/erm/storedproc/followUpDaysProcedure?Procedurecode=` +
      code1 +
      "&Querydate=" +
      dateCode +
      "&start=1";

    await makeAPICall(dataLink, (resp) => {
      const result = resp.responseData;

      setBeginPageNumberDisplay(beginPageNumber.toString());
      setEndPageNumberDisplay(result.pagenation.count);
      setEndPageNumber(result.pagenation.count);

      let updatedInformationGrids = [
        <>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Procedure Code:</div>
            <div className="dataGrid">
              <span id="code1Begin">{result.reponse[0][0].PROCEDURE_CODE}</span>
            </div>
            <div className="dataGrid boldCell">Description:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].PROCEDURE_CODE_DESCRIPTION}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums2">
            <div className="dataGrid boldCell">Global Days:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].FOLLOW_UP_DAYS}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Effective Start Date:</div>
            <div className="dataGrid">
              <span id="effStartDate">
                {result.reponse[0][0].EFFECTIVE_DATE}
              </span>
            </div>
            <div className="dataGrid boldCell">Effective End Date:</div>
            <div className="dataGrid">
              <span id="effEndDate">
                {result.reponse[0][0].EFFECTIVE_EXPIRATION_DATE}
              </span>
            </div>
          </div>
        </>,
      ];
      setInformationGrids(updatedInformationGrids);

      for (let i = 0; i < result.reponse[beginPageNumber - 1].length; i++) {
        let j = i * 6 + 1;
        sourceGrids.push(
          <>
            <div className="dataGrid" key={j}>
              {result.reponse[0][i].SOURCE_CODE}
            </div>
            <div className="dataGrid" key={j + 1}>
              {result.reponse[0][i].KB_SOURCE_YEAR}
            </div>
            <div className="dataGrid" key={j + 2}>
              {result.reponse[0][i].KB_SOURCE_MONTH}
            </div>
            <div className="dataGrid" key={j + 3}>
              {result.reponse[0][i].KB_SOURCE_INFO}
            </div>
            <div className="dataGrid" key={j + 4}>
              {result.reponse[0][i].EDIT_SOURCE_EFFECTIVE_DATE}
            </div>
            <div className="dataGrid" key={j + 5}>
              {result.reponse[0][i].EDIT_SOURCE_EXPIRATION_DATE}
            </div>
          </>
        );
      }
      let updatedSourcesGrids = [
        <>
          <div className="dataGrid boldCell dataTableTitle">Source</div>
          <div className="dataGrid boldCell dataTableTitle">Source Year</div>
          <div className="dataGrid boldCell dataTableTitle">Source Month</div>
          <div className="dataGrid boldCell dataTableTitle">
            Additional Info.
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective Start Date
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective End Date
          </div>
        </>,
        ...sourceGrids,
      ];
      setSourcesGrids(updatedSourcesGrids);

      let descStatement = result.reponse[0][0].DISCLOSURE;
      descStatement = descStatement.replaceAll("\r\n", "\n");
      setEditRationaleStatementText(descStatement);

      setLoading((curr) => !curr);
      setzIndexLoading(-1);
    });
  }

  async function loadPanelData(
    newCode1: number,
    newCode2: number,
    newDateCode: string,
    tempPageNumber: number
  ) {
    setLoading((curr) => !curr);
    setzIndexLoading(10);

    const dataLink =
      `${apiURL}/api/v1/erm/storedproc/followUpDaysProcedure?Procedurecode=` +
      code1 +
      "&Querydate=" +
      dateCode +
      "&start=" +
      tempPageNumber +
      "&size=1";

    await makeAPICall(dataLink, (resp) => {
      const result = resp.responseData;

      beginPageNumber = tempPageNumber;
      setBeginPageNumber(tempPageNumber);
      setBeginPageNumberDisplay(tempPageNumber.toString());
      setEndPageNumberDisplay(result.pagenation.count);

      let updatedInformationGrids = [
        <>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Procedure Code:</div>
            <div className="dataGrid">
              <span id="code1Begin">{result.reponse[0][0].PROCEDURE_CODE}</span>
            </div>
            <div className="dataGrid boldCell">Description:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].PROCEDURE_CODE_DESCRIPTION}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums2">
            <div className="dataGrid boldCell">Global Days:</div>
            <div className="dataGrid">
              <span id="code1BeginDesc">
                {result.reponse[0][0].FOLLOW_UP_DAYS}
              </span>
            </div>
          </div>
          <div className="dataGridContainer-colums4">
            <div className="dataGrid boldCell">Effective Start Date:</div>
            <div className="dataGrid">
              <span id="effStartDate">
                {result.reponse[0][0].EFFECTIVE_DATE}
              </span>
            </div>
            <div className="dataGrid boldCell">Effective End Date:</div>
            <div className="dataGrid">
              <span id="effEndDate">
                {result.reponse[0][0].EFFECTIVE_EXPIRATION_DATE}
              </span>
            </div>
          </div>
        </>,
      ];
      setInformationGrids(updatedInformationGrids);

      sourceGrids = [];
      for (let i = 0; i < result.reponse[0].length; i++) {
        let j = i * 6 + 1;
        sourceGrids.push(
          <>
            <div className="dataGrid" key={j}>
              {result.reponse[0][i].SOURCE_CODE}
            </div>
            <div className="dataGrid" key={j + 1}>
              {result.reponse[0][i].KB_SOURCE_YEAR}
            </div>
            <div className="dataGrid" key={j + 2}>
              {result.reponse[0][i].KB_SOURCE_MONTH}
            </div>
            <div className="dataGrid" key={j + 3}>
              {result.reponse[0][i].KB_SOURCE_INFO}
            </div>
            <div className="dataGrid" key={j + 4}>
              {result.reponse[0][i].EDIT_SOURCE_EFFECTIVE_DATE}
            </div>
            <div className="dataGrid" key={j + 5}>
              {result.reponse[0][i].EDIT_SOURCE_EXPIRATION_DATE}
            </div>
          </>
        );
      }
      let updatedSourcesGrids = [
        <>
          <div className="dataGrid boldCell dataTableTitle">Source</div>
          <div className="dataGrid boldCell dataTableTitle">Source Year</div>
          <div className="dataGrid boldCell dataTableTitle">Source Month</div>
          <div className="dataGrid boldCell dataTableTitle">
            Additional Info.
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective Start Date
          </div>
          <div className="dataGrid boldCell dataTableTitle">
            Effective End Date
          </div>
        </>,
        ...sourceGrids,
      ];
      setSourcesGrids(updatedSourcesGrids);

      let descStatement = result.reponse[0][0].DISCLOSURE;
      descStatement = descStatement.replaceAll("\r\n", "\n");
      setEditRationaleStatementText(descStatement);

      setLoading((curr) => !curr);
      setzIndexLoading(-1);
    });
  }
  return (
    <>
      <GlobalStyles />
      <OverlayWrapper style={{ zIndex: zIndexLoading }}>
        <LoadingIndicator
          loading={loading}
          displayOverlay={true}
          size="l"
          loadingText="Loading, please wait..."
        ></LoadingIndicator>
      </OverlayWrapper>
      <div className={loading ? "globalLoadingScreens" : ""}></div>
      <div className="fillerBox"></div>
      <div className="templateContainer">
        <div className="mainSideContainer">
          <StoryWrapperBreadCrumb className="breadcrumbContainer">
            <Text size="large">
              <LinkPages to="/" className="breadcrumbHome">
                Home &nbsp; &gt;
              </LinkPages>
            </Text>
            <Text size="large">Global Days</Text>
          </StoryWrapperBreadCrumb>
          <div className="searchBoxContainer searchBox2">
            <Card className="searchBox">
              <div className="codeRow1">
                <div className="item1">
                  <Label required>Procedure Code</Label>
                  <input
                    type="text"
                    id="code1"
                    onChange={handleCode1}
                    value={code1}
                  />
                </div>
              </div>
              <div className="codeRow1" id={screenWidth < 1205 ? '' : 'flex'}>
                <div className={"item1 " + (screenWidth < 1205 ? '' : 'width50')}>
                  <Label className="mr-base mt-sm">Date</Label>
                  <div id="flex">
                    <input
                      type="date"
                      id="dateCode"
                      onChange={handleDateCode}
                      value={dateCode}
                    />
                  </div>
                </div>
                <div className="item1 buttonBox">
                  <Button onPress={() => testApi()}>Search</Button>
                </div>
              </div>
            </Card>
          </div>
          <div className="searchBoxContainer topGap">
            <StoryWrapperSideContent>
              <Card className="searchBox">
                <Heading level="h4">Search Result</Heading>
                <StoryWrapperGrid>
                  <div className="dataGridContainer-colums2 allowScroll">
                    <div className="dataGrid boldCell dataTableTitle positionSticky"></div>
                    <div className="dataGrid boldCell dataTableTitle positionSticky">
                      Procedure Code
                    </div>

                    {viewAllCodesGrids}
                  </div>
                  {textElement}
                </StoryWrapperGrid>
              </Card>
              <Card className="topGap">
                <Text className="topGap40">
                  Enter the five-character code for the paid and/or denied
                  procedure and click [Query by date] to find the edit
                  rationale. You may enter a Query date to return the edits as
                  they existed at that date or accept the default. <br />
                  Click [Multiple] to view a list of pay/deny code combinations
                  in spreadsheet format. After querying a procedure code, you
                  can click on the triangle in the box to the right of the
                  "Description" field to view the full description for that
                  code. <br />
                  To re-query, click [Clear query criteria / New query], enter
                  new criteria, and click [Query by date]. The effective dates
                  of the unbundle edit appear in the top section. A blank
                  Effective end date indicates that the edit is currently
                  active. CPT only © 2022 American Medical Association. All
                  Rights Reserved.
                </Text>
              </Card>
            </StoryWrapperSideContent>
          </div>
        </div>
        <div className="mainContainer">
          <div>
            <div className="containerTitle">
              <Heading>Global Days</Heading>
              <StoryWrapper>
                <Text className="displayPageNumber">
                <span style={{color: 'red'}}>{errorDisplay} &nbsp;&nbsp;</span>
                  Displaying <span id="beginPageNumber">{beginPageNumber}</span>{" "}
                  of <span id="endPageNumber">{endPageNumber}</span>
                </Text>
                <Button
                  variant="tertiary"
                  className="buttonTest"
                  disabled={beginPageNumber === 1}
                  onPress={pageDown}
                >
                  Prev
                </Button>
                <Button
                  variant="tertiary"
                  disabled={beginPageNumber >= endPageNumber}
                  onPress={pageUp}
                >
                  Next
                </Button>
              </StoryWrapper>
            </div>
            <Panel.Group>
              <Panel titleAs="h2" title="Information">
                {informationGrids}
              </Panel>
              <Panel title="Sources">
                <StoryWrapperGrid>
                  <div className="dataGridContainer-colums6">
                    {sourcesGrids}
                  </div>
                </StoryWrapperGrid>
              </Panel>
              <Panel title="Edit Rationale Statement" initiallyClosed={false}>
                <Text
                  size="medium"
                  id="ERStatement"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {editRationaleStatementText}
                </Text>
              </Panel>
            </Panel.Group>
          </div>
        </div>
      </div>
    </>
  );
};
