import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { Provider } from "react-redux";
import { coreReducerSlice } from "./core/core-reducer";

/** This is the combined reducer that generates the redux Rootstate */
export const store: any = configureStore({
  reducer: {
    coreReducer: coreReducerSlice.reducer,
  },
});

/**
 * @component ReduxProvider
 * @description This provides access to the redux store to the components
 * which are children to this component.
 * @returns ReduxProvider
 * @example ```<ReduxProvider />```
 */
export const ReduxProvider = ({ children }: { children?: React.ReactNode }) => {
  return <Provider store={store}>{children}</Provider>;
};
