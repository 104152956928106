/**
 * @constant fontColor
 * @description Font colors are declared in this constant and is used in several styled-components.
 */
export const fontColor = {
    white: '#ffffff',
    heather: '#b4c1cf',
    blackPearl: '#181e25',
    midnightExpress: `#00000026`,
    sideNavBlue: '#0860c4',
    comet: '#54657a',
    denim: '#2661BD',
    grey: '#808080',
    green: '#2d9c67',
    darkBlue: '#0f4684',
    jasmine: '#ffe68c',
    greyChateau: '#9da6ab',
    solitude: '#e6eaef',
    aliceBlue: '#f3f5f7',
    bunker: '#202832',
    whiteSmoke: '#f2f2f2',
    blueStreak: '#2661bd',
    gray95: '#f2f2f2',
};
