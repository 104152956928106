/**
 * SSO API's
 */
import config from '../config/config';
import { post, get } from '../utils/utils';

/**
 *  Authenticate with openID and get the code
 *  @param state a string that specifies an optional parameter that is used to maintain state between the logout request and the callback to the endpoint specified by the post_logout_redirect_uri query parameter.
 */
const getCode = (state: string) => {
    if (state && state !== null && state !== '') {
    let authUrl =
        `${getIDPUrl()}/auth?` +
        `client_id=${config.clientId}&` +
        `redirect_uri=${config.redirectUri}&` +
        `response_type=${
            config.responseType ? config.responseType : 'token id_token'
        }` +
        (config.scope ? `&scope=${config.scope}` : '') +
        (state ? `&state=${state}` : '')
    window.location.replace(authUrl);
    }
};

/**
 * Obtain an access token by exchanging the code received
 * @param code a string that specifies the authorization code returned by the authorization server. This property is required only if the grant_type is set to authorization_code
 */
const getAccessToken = (code: string) => {
    return post(
        `${getIDPUrl()}/token`,
        { 'Content-Type': 'application/x-www-form-urlencoded' },
        `grant_type=${config.grantType}&code=${code}&client_id=${config.clientId}&client_secret=${config.clientSecret}&state=${sessionStorage.getItem('state')}` +
            (config.redirectUri ? `&redirect_uri=${config.redirectUri}` : ''),
    );
};

const getAccessTokenERM = (code: string) => {

    return get(
        `${getAuthTokenURL()}?code=${code}&client_id=${config.clientId}&client_secret=${config.clientSecret}&state=${sessionStorage.getItem("state")}${config.redirectUri ? `&redirect_uri=${config.redirectUri}` : ""}`
    )
}

const getIDPUrl = () => {
    return config.SSO_URI;
};

const getAuthTokenURL = () => config.SSO_AUTH_URI;

export default {
    getCode,
    getAccessToken,
    getAccessTokenERM
};
