import React, { useState, useEffect, useContext } from "react";
import { Footers } from "../Footers";
import "./Welocme.component.css";
import { usePageVisit } from "./PageVisited";
import { Link } from "react-router-dom";

import Woman1 from "../../assets/woman1.png";
import styled from "styled-components";
import { Avatar } from "@uitk/react";

const ContentMedia = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-column: repeat(12, 1fr);
  grid-auto-rows: min-content;
`;

const Content = styled.div`
  grid-column: 1 / 8;
`;

const MediaContainer = styled.div`
  grid-column: 8 / 12;
`;

const Media = styled.img`
  width: 100%;
  height: 90%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: 50% 50%;
`;

export const Welcome: React.FC = () => {
  const { recentlyViewed } = usePageVisit();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    if (!localStorage.getItem("modalShown")) {
      setIsModalOpen(true);
    }

    //   checkCreds();
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem("modalShown", "true");
    setIsModalOpen(false);
  };

  //add your access token
  localStorage.setItem("token", "");

  return (
    <>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Rationale Module - Copyright © {currentYear} Optum</h2>
            <p>
              {" "}
              All rights reserved. Printed in the United States of America. No
              part of this publication may be reproduced or transmitted in any
              form or by any means electronic or mechanical, including
              photocopy, recording, or storage in a database or retrieval
              system, without the prior permission of the publisher. This
              publication is made available with the understanding that the
              publisher is not engaged in rendering legal or other services that
              require a professional license. The Edit Rationale Module (ERM)
              contains edit rationale and sourcing data that correspond to the
              Claims Editing KnowledgeBase. The Claims Editing KnowledgeBase
              contains proprietary claims editing rules and guidelines developed
              by Optum. The Claims Editing KnowledgeBase is provided to Optum
              customers for informational purposes and is a tool that a customer
              may use in various ways in its internal business. An Optum
              customer may customize or delete any edit from its version of the
              Claims Editing KnowledgeBase, or add additional edits, pursuant to
              each customer's own reimbursement policies and decisions. Each
              Claims Editing KnowledgeBase client is responsible for decisions
              it makes and actions it takes using the Claims Editing
              KnowledgeBase. The Claims Editing KnowledgeBase is designed and
              intended for use by professionals experienced in the uses and
              limitations of code editing, claims processing, medical bill
              review, and claims repricing, and it is the client's
              responsibility to ascertain the suitability of the Claims Editing
              KnowledgeBase for client's purposes. A customer's reliance upon,
              interpretation of and/or use of the Claims Editing KnowledgeBase
              is solely and exclusively at the discretion of each customer. Each
              customer's determination or establishment of an appropriate
              reimbursement level or fee, and its claims adjudication criteria
              are solely within that customer's discretion, regardless of
              whether the customer uses the Claims Editing KnowledgeBase. Optum
              does not determine, on any customer's behalf, the appropriate fee
              or reimbursement policies for that customer and its business.
              Optum has not reviewed or assessed its customers' insurance or
              health plan documents and policies and has not made any
              determination that the Claims Editing KnowledgeBase complies with
              every customer's documents, policies or legal obligations. CPT
              codes, descriptions, and other CPT material only are copyright
              [YEAR] American Medical Association (AMA). All Rights Reserved.
              AMA does not directly or indirectly practice medicine or dispense
              medical services. AMA assumes no liability for data contained or
              not contained herein. The responsibility for the content of any
              'National Correct Coding Policy' included in this product is with
              the Centers for Medicare & Medicaid Services (CMS), formerly known
              as the Health Care Financing Administration or HCFA and no
              endorsement by the AMA is intended or should be implied. The AMA
              disclaims responsibility for any consequences or liability
              attributable to or related to any use, nonuse or interpretation of
              information contained in this product. The Code on Dental
              Procedures and Nomenclature is published in 'Current Dental
              Terminology (CDT©),' [YEAR] American Dental Association (ADA). All
              rights reserved. This document is designed to be an accurate and
              authoritative source of information about ERM™. Every effort has
              been made to verify the accuracy of the information, and all
              information is believed reliable at the time of publication.
              Absolute accuracy cannot be guaranteed, however. Since the policy
              of Optum is to make continuous improvements, this manual is
              subject to change without notice. All attempts are made to ensure
              that the information presented in this manual is correct. However,
              if you should find any error, please notify an Optum service
              representative. No liability, expressed or implied, will be
              assumed by Optum or its affiliates for any damage resulting from
              the use of the information in this manual.{" "}
            </p>
            <button onClick={handleCloseModal} className="continue-button">
              Continue
            </button>
          </div>
        </div>
      )}
      <div className="container">
        <div className="content">
          <ContentMedia>
            <Content>
              <h1>About Edit Rationale Module</h1>
              <p>
                The Optum Edit Rationale Module (ERM) is to be used by customers
                of Optum's Commercial Professional Claims Editing KnowledgeBase.
                ERM provides Edit Rationale Statements (ERS) and source
                information for certain edits that are provided for customers of
                Optums Commercial Professional Claims Editing Knowledgebase.
              </p>
              <p>
                Edit Rationale Statements are an explanation of the rationale or
                philosophy of KnowledgeBase edits and relationships. There are
                some Edit Rationale Statements that deal with specific edits,
                but most Edit Rationale Statements encompass many edits and/or
                relationships. Specific codes generally listed in the ERS are
                given as examples of edits that are in the KnowledgeBase. There
                may be large numbers of edits that are tied to an ERS based on
                the philosophy of the Edit Rationale Statement.
              </p>
              <p>
                Sources are assigned at the edit level to indicate the origin or
                basis for the edit. These sources are typically
                industry-recognized authorities such as the Correct Coding
                Initiative (CCI) or Current Procedural Terminology (CPT)™ . The
                source will also indicate if the edit is specifically taken from
                a recognized authority (definitive) or if it is simply based on
                the same philosophy as the authority (interpreted)..
              </p>
            </Content>
            <MediaContainer>
              <Media
                src={Woman1}
                alt="Image floating to the side of a paragraph."
              />
            </MediaContainer>
          </ContentMedia>
        </div>
        <div className="recently-viewed">
          <h2>Recently Viewed</h2>
          <div className="cards-container">
            {recentlyViewed.map((entry, index) => (
              <div key={index} className="card">
                <div className="card__headline">
                  <h3>{entry.title}</h3>
                  <p>{entry.summary}</p>
                </div>
                <Link to={entry.path}>Go</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <Footers /> */}
    </>
  );
};