import React, { Fragment } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Header, Avatar, Footer } from "@uitk/react";
import { MenuBar } from "../MenuBar";

import Logo from "../../svg/Logo.svg";
import { Outlet, useLocation } from "react-router-dom";
import { coreData } from "@store/index";

const LogoutButton = styled.button`
    marginLeft: 10px;
    background: rgb(255, 97, 43);
    color: white;
    font-family: 'Optum Sans';
    display: flex;
    padding: 1px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    border: none; 
    display: inline-block;
    border-radius: 24px;
    align-items: center;
    text-align: center; 
    justify-content: center;
    &:hover {
      background: #ff8000;
      color: white;
      a {
        color: white;
      }
`;

const RightNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const GlobalFooter = styled.div`
position: relative;
`;
const currentYear = new Date().getFullYear();
const linkConfig = {
  links: [
    {
      href: "https://www.optum.com/about-us.html",
      label: "About Us",
      openInNewTab: true,
    },
    {
      href: "https://www.optum.com/contact-us.html",
      label: "Contact Us",
      openInNewTab: true,
    },
    {
      href: "https://www.optum.com/more-optum-sites.html",
      label: "More Optum Sites",
      openInNewTab: true,
    },
  ],
};

const logo = (
  <img
    src="https://www.optum.com/content/dam/optum4/images/logos/optum-logo-ora-rgb1.svg"
    className="uitk-footer__logo--small uitk-footer__logo--medium uitk-footer__logo--large"
    alt="logo"
  />
);

type ApplicationShellProps = {
  logoutHandler: () => void;
};

export const ApplicationShell: React.FC<ApplicationShellProps> = ({
  logoutHandler,
}) => {
  const { userFirstName, userLastName } = coreData();
  const location2 = useLocation().pathname;

  const regularFooter = (
    <GlobalFooter>
      <Footer linkConfig={linkConfig} logoContent={logo}>
            CPT only © {currentYear} American Medical Association. All Rights
            Reserved
      </Footer>
    </GlobalFooter>
  );
  const dataFooter = (
    <GlobalFooter>
      <Footer linkConfig={linkConfig} logoContent={logo}>
            CPT only © {currentYear} American Medical Association. All Rights
            Reserved
      </Footer>
    </GlobalFooter>
  );
  
  return (
    <Fragment>
      <Header
        className="site-header"
        logoContent={
          <React.Fragment>
            <div>
              <img src={Logo} alt="logo" />
              <div
                style={{
                  color: "#002677",
                  fontFamily: "Optum Sans",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "150%",
                  marginTop: "5px",
                  marginBottom: "-10px",
                }}
              >
                Edit Rationale Module
              </div>
            </div>
            <RightNavContainer>
              <Avatar
                label={[userFirstName, " ", userLastName]}
                initials={`${userFirstName.toUpperCase()[0]}${
                  userLastName.toUpperCase()[0]
                }`}
                backgroundColor="primary"
                style={{
                  width: "min-content",
                }}
              />
              {/* <LogoutButton onClick={logoutHandler}>Logout</LogoutButton> */}
            </RightNavContainer>
          </React.Fragment>
        }
      />
      <MenuBar />
      <Outlet data-testid="outlet" />
      {location2 === "/Usermanual" || location2 === "/Administration" || location2 === "/" ? dataFooter : ""}
    </Fragment>
  );
};
