import React from "react";
import { userRoles } from "@models/interfaces/user-roles";
import { Administration } from "@components/Administration";
import { Anesthesia } from "@components/Anesthesia";
import { DiagnosisInvalidGender } from "@components/DiagnosisEdits/DiagnosisInvalidGender";
import { DiagnosisValidAge } from "@components/DiagnosisEdits/DiagnosisValidAge";
import { InvalidDiagnosisCombination } from "@components/DiagnosisEdits/InvalidDiagnosisCombination";
import { InvalidDiagnosisModifier } from "@components/DiagnosisEdits/InvalidDiagnosisModifier";
import { NoPrimaryDiagnosis } from "@components/DiagnosisEdits/NoPrimaryDiagnosis";
import { PrimaryDiagnosis } from "@components/DiagnosisEdits/PrimaryDiagnosis";
import { Modifier } from "@components/Modifier";
import { PayorDeny } from "@components/PayorDeny";
import { PlaceofService } from "@components/PlaceofService";
import { ProcedureDiagnosis } from "@components/ProcedureDiagnosis";
import { GlobalDays } from "@components/ProcedureEdits/GlobalDays";
import { MaxFrequencyPerDay } from "@components/ProcedureEdits/MaxFrequencyPerDay";
import { MultipleProcedureReduction } from "@components/ProcedureEdits/MultipleProcedureReduction";
import { ProcedureInvalidGender } from "@components/ProcedureEdits/ProcedureInvalidGender";
import { ProcedureValidAge } from "@components/ProcedureEdits/ProcedureValidAge";
import { UserManual } from "@components/UserManual";
import { Welcome } from "@components/Welcome";

import "@uitk/foundations/Brand_optum/fonts.css";
import "@uitk/foundations/Brand_uhc/fonts.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ThirdPartyLiability } from "@components/DiagnosisEdits/ThirdPartyLiability";

import { CoreIdpAuth } from "@auth/index";
import { coreData } from "@store/index";
import { ApplicationShell } from "@components/ApplicationShell";
import { LogoutPage } from "@components/logout-page";
import "./WorkSpaceStyles.css";
import { ErrorPage } from "@components/custom-error-page";
import { NetworkError } from "@components/network-error-page";
import { AccessDenied } from "@components/access-denied-page";
import { PageNotFound } from "@components/pagenotfound-page";

export const WorkSpace: React.FC = () => {
  const { userRole, userEmail, setIsloggedIn } = coreData();
  const navigate = useNavigate();

  // logout function handling
  const handleLogout = () => {
    navigate("/Logout");
  };
  //specifying user role can be change with role determination logic

  return (
    <Routes>
      <Route element={<CoreIdpAuth />}>
        <Route element={<ApplicationShell logoutHandler={handleLogout} />}>
          <Route path="/" element={<Welcome />} />
          <Route path="/PayorDeny" element={<PayorDeny />} />
          <Route path="/Modifier" element={<Modifier />} />
          <Route
            path="/MultipleProcedureReduction"
            element={<MultipleProcedureReduction />}
          />
          <Route path="/GlobalDays" element={<GlobalDays />} />
          <Route
            path="/DiagnosisInvalidGender"
            element={<DiagnosisInvalidGender />}
          />
          <Route path="/DiagnosisValidAge" element={<DiagnosisValidAge />} />
          <Route
            path="/InvalidDiagnosisCombination"
            element={<InvalidDiagnosisCombination />}
          />
          <Route
            path="/InvalidDiagnosisModifier"
            element={<InvalidDiagnosisModifier />}
          />
          <Route path="/NoPrimaryDiagnosis" element={<NoPrimaryDiagnosis />} />
          <Route path="/PrimaryDiagnosis" element={<PrimaryDiagnosis />} />
          <Route
            path="/ThirdPartyLiability"
            element={<ThirdPartyLiability />}
          />
          <Route path="/ProcedureDiagnosis" element={<ProcedureDiagnosis />} />
          <Route path="/Anesthesia" element={<Anesthesia />} />
          <Route path="/ProcedureValidAge" element={<ProcedureValidAge />} />
          <Route
            path="/ProcedureInvalidGender"
            element={<ProcedureInvalidGender />}
          />
          <Route path="/MaxFrequencyPerDay" element={<MaxFrequencyPerDay />} />
          <Route path="/UserManual" element={<UserManual />} />
          <Route path="/PlaceofService" element={<PlaceofService />} />

          {/* admin access specifcation */}
          <Route path="/Administration" element={<Administration />} />
        </Route>
      </Route>
      <Route path="/Logout" element={<LogoutPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/networkerror" element={<NetworkError />} />
      <Route path="/accessdenied" element={<AccessDenied />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
