/**
 * @enum This represent the error types in the application
 */
export enum RESPONSE_TYPES {
    ACCESS_DENIED = 'accessDenied',
    NETWORK_ERROR = 'networkError',
    PAGE_NOT_FOUND = 'pageNotFound',
    ERROR = 'error',
    SUCCESS_CODE = 200,
    NOTFOUND_CODE = 'Not Valid Code, Table has no records',
    NOT_AUTHORIZED_CODE = 401,
    NOT_AUTHENTICATED_CODE = 403,
    NOT_VALID_CODE = 404,
    SERVER_ERROR_CODE = 500,
    API_SUCCESS= 'success',
    DATA_TOO_LARGE= 'Data is to large',
    API_FAILED = 'failure',
    ERROR_BOUNDARY= 'errorboundary'
}
