import React, { useState } from "react";
import {
  Spinner,
  SpinnerContainer,
  SpinnerText,
  SpinnerWithText,
} from "./styled";

import { coreData } from "../../store";

/**
 * @component CenteredLoadingSpinner
 * @description This displays a loading spinner into the application when the application is in a
 * state of loading something.
 * @returns CenteredLoadingSpinner
 * @example ```<CenteredLoadingSpinner />```
 */

export const CenteredLoadingSpinner = () => {
  const { loadingText } = coreData();

  return (
    <SpinnerContainer>
      <SpinnerWithText>
        <Spinner data-testid="spinner" loading={true} />
        <SpinnerText>{loadingText}</SpinnerText>
      </SpinnerWithText>
    </SpinnerContainer>
  );
};
