import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { coreData } from "../../store";
import { userRoles } from "../../models/interfaces/user-roles";
import { MenuIcon } from "@uitk/react-icons";

interface SubMenuItem {
  name: string;
  link: string;
}
 
interface MenuItem {
  name: string;
  link: string;
  subLinks: SubMenuItem[];
}
 
const Sidebar = styled.div<{ isOpen: boolean }>`
  width: 255px;
  height: 55pc;
  position: relative;
  top: -1.6pc;
  background-color: #fbf9f4;
  margin-bottom: -57pc;
  z-index: 2000;
  border-left: 1.5px solid #fbf9f4;
  box-shadow: 2px 0 5px rgba(0, 0.1, 0.2, 0.4);
  transform: ${({ isOpen }) => (isOpen ? "translateX(80)" : "translateX(0px)")};
  transition: transform 0.3s ease;
  margin-left: ${({ isOpen }) =>
    isOpen ? "-300px" : "0px"}; // Toggle sidebar view
 
  @media (max-width: 1508px) {
    transform: translateX(0);
    top: -1.6pc;
    margin-left: ${({ isOpen }) =>
      isOpen ? "0px" : "-300px"}; // Toggle sidebar view
  }
  @media (max-width: 1273px) {
    top: -1.6pc;
    margin-left: ${({ isOpen }) =>
      isOpen ? "0px" : "-300px"}; // Toggle sidebar view
  }
  @media (max-width: 1050px) {
    top: -1.6pc;
    margin-left: ${({ isOpen }) =>
      isOpen ? "0px" : "-300px"}; // Toggle sidebar view
  }
`;
 
const MenuList = styled.ul`
  position: relative;
  top: 1px
  list-style-type: none;
  padding: 0;
  background: var(--color-background-navigation-primary-surface-selected, #FBF9F4);
  font-wight: bold;
  margin: 0;
`;

const MenuItemLabel = styled.div`
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: #002677;
    box-shadow: 0px 0px 0px 5px #002677, 0px 0px 0px 2px #fff;
    color: white;
    a {
      color: white;
    }
  }
`;

const MenuItemStyled = styled.li`
  display: flex;
  color: var(--color-text-navigation-primary, #222);
  font-family: Optum Sans;
  position: relative;
  top: 3px;
  left: -12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.048px;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #002677;
    box-shadow: 0px 0px 0px 5px #002677, 0px 0px 0px 2px #fff;
    color: white;
    a {
      color: white;
    }
  }
`;
 
const SubMenuList = styled.ul<{ isOpen: boolean }>`
  list-style-type: none;
  position: relative;
  width: 97%;
  color: var(--color-text-navigation-primary, #222);
  font-family: Optum Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.048px;
  padding: 0;
  margin: 0;
  margin-bottom: -7px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;
 
const SubMenuItemStyled = styled.li`
  padding-left: 30px;
  &:hover {
    background: var(
      --color-background-navigation-primary-surface-hover,
      #002677
    );
    box-shadow: 0px 0px 0px 5px #002677, 0px 0px 0px 2px #fff;
    color: white;
    width: auto;
    a {
      color: white;
    }
  }
`;
 
const NavLinkStyled = styled(Link)`
  text-decoration: none;
  color: black;
  display: block;
  padding: 12px 16px;
  width: 100%;
`;
 
const BurgerIcon = styled.div<{ isOpen: boolean }>`
  display: grid;
  width: 20px;
  height: 20px;
  justify-content: left;
  align-items: left;
  color: rgb(255, 97, 43);
  cursor: pointer;
  position: relative;
  top: 1px;
  font-size: 30px;
  margin-left: ${({ isOpen }) =>
    isOpen ? "0px" : "250px"}; // Toggle sidebar view
  margin-bottom: 7px;
  z-index: 3000;
  font-size: 50px;
  transition: left 0.3s ease;
  &:before {
    //content: "☰";
  }
  @media (max-width: 1500px) {
    top: 0px;
    margin-left: ${({ isOpen }) =>
      isOpen ? "250px" : "0px"}; // Toggle sidebar view
  }
  @media (max-width: 1273px) {
    top: px;
    margin-left: ${({ isOpen }) =>
      isOpen ? "250px" : "0px"}; // Toggle sidebar view
  }
  @media (max-width: 1050px) {
    top: 0px;
    margin-left: ${({ isOpen }) =>
      isOpen ? "250px" : "0px"}; // Toggle sidebar view
  }
`;
const DropdownIcon = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  position: fixed;
  margin-left: ${({ isOpen }) =>
    isOpen ? "0px" : "240px"}; // Toggle sidebar view
  @media (max-width: 1500px) {
    margin-left: ${({ isOpen }) =>
      isOpen ? "240px" : "0px"}; // Toggle sidebar view
  }
  @media (max-width: 1273px) {
    margin-left: ${({ isOpen }) =>
      isOpen ? "240px" : "0px"}; // Toggle sidebar view
  }
  @media (max-width: 1050px) {
    margin-left: ${({ isOpen }) =>
      isOpen ? "240px" : "0px"}; // Toggle sidebar view
  }
`;
 
const menuData: MenuItem[] = [
  { name: "Home", link: "/", subLinks: [] },
  { name: "Pay or Deny", link: "/PayorDeny", subLinks: [] },
  { name: "Modifier", link: "/Modifier", subLinks: [] },
  {
    name: "Procedure Edits",
    link: "",
    subLinks: [
      {
        name: "Multiple Procedure Reduction",
        link: "/MultipleProcedureReduction",
      },
      { name: "Procedure Invalid Gender", link: "/ProcedureInvalidGender" },
      { name: "Procedure Valid Age", link: "/ProcedureValidAge" },
      { name: "Max Frequency Per Day", link: "/MaxFrequencyPerDay" },
      { name: "Global Days", link: "/GlobalDays" },
    ],
  },
  { name: "Place of Service", link: "/PlaceofService", subLinks: [] },
  { name: "Anesthesia", link: "/Anesthesia", subLinks: [] },
  {
    name: "Diagnosis Edits",
    link: "",
    subLinks: [
      { name: "No Primary Diagnosis", link: "/NoPrimaryDiagnosis" },
      { name: "Third Party Liability", link: "/ThirdPartyLiability" },
      { name: "Diagnosis Invalid Gender", link: "/DiagnosisInvalidGender" },
      { name: "Diagnosis Valid Age", link: "/DiagnosisValidAge" },
      {
        name: "Invalid Diagnosis Combination",
        link: "/InvalidDiagnosisCombination",
      },
      { name: "Invalid Diagnosis Modifier", link: "/InvalidDiagnosisModifier" },
      { name: "Primary Diagnosis", link: "/PrimaryDiagnosis" },
    ],
  },
  { name: "Procedure Diagnosis", link: "/ProcedureDiagnosis", subLinks: [] },
  { name: "User Manual", link: "/Usermanual", subLinks: [] },
  { name: "Administration", link: "/Administration", subLinks: [] },
];
 
export const MenuBar: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(location.pathname === "/");
  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
  const { userRole } = coreData();
 
  useEffect(() => {
    setIsOpen(location.pathname != "/");
 
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(location.pathname === "/");
      } else {
        setActiveSubMenu(null);
      }
    };
 
    window.addEventListener("resize", handleResize);
 
    return () => window.removeEventListener("resize", handleResize);
  }, [location.pathname]);
 
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
 
  const toggleSubMenu = (index: number) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };
 
  return (
    <>
      <BurgerIcon isOpen={isOpen} onClick={toggleMenu}>
  <MenuIcon  fill="rgb(255, 97, 43)" size="48"/>
</BurgerIcon>
      <Sidebar isOpen={isOpen}>
        <MenuList>
          {menuData.map((item, index) => {
            return userRole === "User" &&
              item.name === "Administration" ? null : (
              <React.Fragment key={index}>
                <MenuItemStyled onClick={() => toggleSubMenu(index)}>
                  {item.subLinks.length > 0 ? (
                    <MenuItemLabel> {item.name}</MenuItemLabel>
                  ) : (
                    <NavLinkStyled to={item.link}>{item.name}</NavLinkStyled>
                  )}
                  {item.subLinks.length > 0 && (
                    <DropdownIcon isOpen={isOpen}>
                      {activeSubMenu === index ? "▼" : "►"}
                    </DropdownIcon>
                  )}
                </MenuItemStyled>
                <SubMenuList isOpen={activeSubMenu === index}>
                  {item.subLinks.map((subItem, subIndex) => (
                    <SubMenuItemStyled key={subIndex}>
                      <NavLinkStyled to={subItem.link}>
                        {subItem.name}
                      </NavLinkStyled>
                    </SubMenuItemStyled>
                  ))}
                </SubMenuList>
              </React.Fragment>
            );
          })}
        </MenuList>
      </Sidebar>
    </>
  );
};