import React, { useState } from "react";
import { createGlobalStyle } from "styled-components";
import "./UserManualStyles.css"
import { MenuIcon } from "@uitk/react-icons";
import { Text, Heading, Card, TabbedPanel, Footer } from "@uitk/react";
import styled from "styled-components";
import { Link as LinkPages } from "react-router-dom";
import { Footers } from "../Footers";
import { position, size } from "polished";

const panels = [
    {
        header: "Statements and Sourcing",
        content: (
            <>
                <TabbedPanel.Heading><Heading level="h4">Statements and Sourcing</Heading></TabbedPanel.Heading>
                <TabbedPanel.Content>
                    The Optum Edit Rationale Module (ERM) is to be used by customers of Optum’s
                    Commercial Professional Claims Editing KnowledgeBase. ERM provides Edit
                    Rationale Statements (ERS) and source information for certain edits that
                    are provided in the Claims Editing KnowledgeBase.
                    Edit Rationale Statements (ERS) Platform<br /><br />

                    <span className="tabbedTitle">Edit Rationale Statements</span> <br />
                    Edit Rationale Statements are an explanation of the rationale or philosophy of
                    KnowledgeBase edits and relationships. There are some Edit Rationale Statements that
                    deal with specific edits, but most Edit Rationale Statements encompass many edits
                    and/or relationships. Specific codes generally listed in the ERS are given as
                    examples of edits that are in the KnowledgeBase. There may be large numbers of
                    edits that are tied to an ERS based on the philosophy of the Edit Rationale
                    Statement. <br /><br />

                    <span className="tabbedTitle">Sourcing</span> <br />
                    Sources are assigned at the edit level to indicate the origin or basis for the edit.
                    These sources are typically industry-recognized authorities such as the Correct
                    Coding Initiative (CCI) or Current Procedural Terminology (CPT). The source will
                    also indicate if the edit is specifically taken from a recognized authority
                    (definitive) or if it is simply based on the same philosophy as the authority
                    (interpreted).
                </TabbedPanel.Content>
            </>
        ),
    },
    {
        header: "Searching Edit Rationale",
        content: (
            <>
                <TabbedPanel.Heading><Heading level="h4">Searching Edit Rationale</Heading></TabbedPanel.Heading>
                <TabbedPanel.Content>
                    The Optum Edit Rationale Module (ERM) is to be used by customers of Optum’s
                    Commercial Professional Claims Editing KnowledgeBase. ERM provides Edit
                    Rationale Statements (ERS) and source information for certain edits that are
                    provided in the Claims Editing KnowledgeBase.
                    Edit Rationale Statements (ERS) Platform<br/><br/>

                    <span className="tabbedTitle">Wildcard Searching</span><br/>
                    Edit Rationale Statements are an explanation of the rationale or philosophy of KnowledgeBase
                    edits and relationships. There are some Edit Rationale Statements that deal with
                    specific edits, but most Edit Rationale Statements encompass many edits and/or
                    relationships. Specific codes generally listed in the ERS are given as examples of
                    edits that are in the KnowledgeBase. There may be large numbers of edits that are
                    tied to an ERS based on the philosophy of the Edit Rationale Statement.<br/><br/>

                    <span className="tabbedTitle">Search Criteria</span><br/>
                    Sources are assigned at the edit level to indicate the origin or basis for the edit.
                    These sources are typically industry-recognized authorities such as the Correct
                    Coding Initiative (CCI) or Current Procedural Terminology (CPT). The source will
                    also indicate if the edit is specifically taken from a recognized authority
                    (definitive) or if it is simply based on the same philosophy as the authority
                    (interpreted).<br/><br/>

                    <span className="tabbedTitle">Search Results</span><br/>
                    Sources are assigned at the edit level to indicate the origin or basis for the edit.
                    These sources are typically industry-recognized authorities such as the Correct
                    Coding Initiative (CCI) or Current Procedural Terminology (CPT). The source will
                    also indicate if the edit is specifically taken from a recognized authority
                    (definitive) or if it is simply based on the same philosophy as the authority
                    (interpreted).
                </TabbedPanel.Content>
            </>
        ),
    },
    {
        header: "Support",
        content: (
            <>
                <TabbedPanel.Heading><Heading level="h4">Optum Support for Edit Rationale Module</Heading></TabbedPanel.Heading>
                <TabbedPanel.Content>
                    For questions or to report technical difficulties call: 1-800-765-6818 from 7am to 5pm
                    Mountain Standard Time.
                </TabbedPanel.Content>
            </>
        ),
    },
];

export const UserManual: React.FC = () => {
    const [currentPanel, setCurrentPanel] = useState(0);

    const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
    html, body, #react-root {
        height: 100%;
        min-height: 1500px;
    }
`;

    const StoryWrapperSideContent = styled.div`
    .breadcrumbHome {
      color: rgba(54, 123, 245, 1);
      heighr
    }
    .breadcrumbHome:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    .searchBox {
      border-top: 4px solid rgba(255, 97, 43, 1);
      outline: 1px solid rgba(255, 255, 255, 1);
      height: auto;
    }
    TextArea {
      height: 36px;
    }
    .item1 {
      width: 50%
    }
  }
`;

    const linkConfig = {
        links: [
            {
                href: "/",
                label: "Link1",
            },
            {
                href: "https://uitoolkit-react.optum.com/?path=/story/getting-started-installation--page",
                label: "Link2",
                openInNewTab: true,
            },
            {
                href: "https://uitoolkit-react.optum.com/?path=/story/getting-started-installation--page",
                label: "Link3",
                openInNewTab: true,
            },
        ],
    };

    const StoryWrapperSideContent2 = styled.div`
    .breadcrumbHome {
        color: rgba(54, 123, 245, 1);
        height: auto;
        text-decoration: none;
      }
      .breadcrumbHome:hover {
        text-decoration: underline;
        cursor: pointer;
      }
`;

    return (
        <>
        {/* <GlobalStyles /> */}
        <StoryWrapperSideContent2 className="storyWrapperSideContent">
            <div className="spaceing">
                <MenuIcon fill="#ff612b" size="0" isDecorative={true} />
            </div>
            <div className="breadcrumbContainer">
            <Text size="large">
              <LinkPages to="/" className="breadcrumbHome">
                Home &nbsp; &gt;
              </LinkPages>
            </Text>
                <Text size="large">User Manual</Text>
            </div>
        </StoryWrapperSideContent2>
        <div className="tempContainer" style={{minHeight:"70vh"}}>
            <Heading>User Manual</Heading>
        <Card>
            <TabbedPanel
                    panels={panels}
                    onHeaderPress={setCurrentPanel}
                    selectedPanel={currentPanel}
                    titlesAs="h3"
            />
        </Card>
        </div>
        </>
    );
};