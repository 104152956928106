import { RESPONSE_TYPES } from "@models/enums/response-types";
import { getErrorPage } from "@utils/error-pages-utility";
import { FC, ReactElement } from "react";
/**
 * @component PageNotFound
 * @description Displays error when page is not found.
 * @returns PageNotFound
 * @example ```<PageNotFound />```
 */
export const PageNotFound: FC<any> = (): ReactElement => {
  return getErrorPage(RESPONSE_TYPES.PAGE_NOT_FOUND);
};
