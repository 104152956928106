import React, { FC, ReactElement, useEffect } from "react";
import {
  StyledDescription,
  StyledLogoutContainer,
  StyledLogoutTitle,
} from "./styled";
import { coreData } from "../../store";
import { COREAPP_CONFIG_DIRECTORY } from "../../config";
import { apiWrapper } from "../../services/api-wrapper";
import {
  setCodeToken,
  setStoredAccessToken,
} from "../../auth/core-idp/utils/utils";
import { Button } from "@uitk/react";
import { useNavigate } from "react-router-dom";
/**
 * @component LogoutPage
 * @description Displays the LogoutPage page.
 * @returns LogoutPage
 * @example ```<LogoutPage />```
 */
export const LogoutPage: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const { setUserId, setUserAccess, setIsloggedIn, setUserEmail, userEmail } =
    coreData();

  useEffect(() => {
    apiWrapper.get(
      `${
        process.env.REACT_APP_SSO_URL
      }/auth/realms/developer-platform/protocol/openid-connect/logout?post_logout_redirect_url=${
        process.env.REACT_APP_SSO_REDIRECT_URL
      }&id_token_hint=${sessionStorage.getItem("ACCESS_TOKEN")}&code=${null}`,
      null
    ).then( () => {
      setCodeToken("");
      setUserId(0);
      setUserAccess(0);
      setIsloggedIn(false);
      setUserEmail("");
      sessionStorage.removeItem("isLoggedIn");
      sessionStorage.removeItem("userEmail");
      setStoredAccessToken("");
      location.reload
    }
      
    );
  }, []);

  const clearSession = () => {
    setCodeToken("");
    setUserId(0);
    setUserAccess(0);
    setIsloggedIn(false);
    setUserEmail("");
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("userEmail");
    setStoredAccessToken("");
  };

  const handleLogin = () => {
    navigate(process.env.REACT_APP_SSO_URL);
  };

  return (
    <>
      {/** Check if user is loggedIn */}
      <>
        <StyledLogoutContainer data-testid={`erm_logout-page`}>
          <StyledLogoutTitle data-testid={`erm_logout-page-title`}>
            You Have Successfully Logged Out.
          </StyledLogoutTitle>
          <StyledDescription data-testid={`erm_logout-page-description`}>
            <Button onPress={handleLogin}>Login</Button>
          </StyledDescription>
        </StyledLogoutContainer>
      </>
    </>
  );
};
