import { RESPONSE_TYPES } from "@models/enums/response-types";
import { FC, ReactElement } from "react";
import { getErrorPage } from "@utils/error-pages-utility";

/**
 * @component NetworkError
 * @description Displays error when network error occurs.
 * @returns NetworkError
 * @example ```<NetworkError />```
 */
export const NetworkError: FC<any> = (): ReactElement => {
  return getErrorPage(RESPONSE_TYPES.NETWORK_ERROR);
};
